import React, { useState } from 'react';
import { Container }       from "reactstrap";

//Import Breadcrumb
import Breadcrumbs              from '../../components/Common/Breadcrumb';
import {
    FORM_CONTEXT_SKILL_CREATE,
    FORM_CONTEXT_SPEC_CREATE,
    SPEC_TYPE_ANT,
    SPEC_TYPE_BHV,
    SPEC_TYPE_CSQ,
    SPEC_TYPE_SKL
}                               from "../../components/Common/Constants";
import { PMAssociationBuilder } from "./PMAssociationBuilder";


export const initialFormState = {
    "id": "0000-0000-0000",
    "key": "",
    "display": "",
    "description": "",
    "weight": 0,
    "type": SPEC_TYPE_ANT,
    "associations": 0,
    "actions": "",
    "context": FORM_CONTEXT_SPEC_CREATE
};


const PMMapFormSkillSpec = (props) => {
    
    const types = [SPEC_TYPE_ANT, SPEC_TYPE_BHV, SPEC_TYPE_CSQ, SPEC_TYPE_SKL];
    
    const specificationType = props.location.state ? props.location.state.type : false;
    
    let t = initialFormState;
    let breadcrumbTitle = 'Add Specification';
    let breadcrumbItem = 'Specification Management';
    if (specificationType !== false && types.includes(specificationType)) {
        t = {...initialFormState, type: specificationType};
        if (specificationType === SPEC_TYPE_SKL) {
            t = {...t, context: FORM_CONTEXT_SKILL_CREATE};
            breadcrumbTitle = 'Add Skill';
            breadcrumbItem = 'Skill Management';
        }
    }
    
    const [createFormInitialState, setCreateFormInitialState] = useState(JSON.stringify(t));
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbItem}/>
                    
                    
                    <PMAssociationBuilder content={createFormInitialState} closeFxn={() => {
                    }}/>
                
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PMMapFormSkillSpec;
