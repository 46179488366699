import React, { useEffect, useState }                         from "react";
import { useForm }                                            from "react-hook-form";
import { Redirect }                                           from "react-router-dom";
import BarLoader                                              from "react-spinners/BarLoader";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { useMutation, useQuery }                              from "urql";
import "../../assets/scss/custom.scss"
import { API_URL }                                            from "../../App";

const M_UPDATE_CAREGIVER = `
 mutation updateCaregiver($id: String!, $firstName: String!, $lastName: String!, $emailOrPhone: String!, $usePhone: Boolean!, $useToken: Boolean!) {
     updateCaregiver(id: $id, firstName: $firstName, lastName: $lastName, emailOrPhone: $emailOrPhone, usePhone: $usePhone, useToken: $useToken)
 }
`;

const M_REGISTER_CAREGIVER = `
mutation registerCaregiver($firstName: String, $lastName: String, $emailOrPhone: String, $password: String!, $token: String, $phone: String, $usePhone: Boolean!, $useToken: Boolean!, $timezoneCode: String, $gender: String, $race: String, $ethnicity: String) {
  registerCaregiver(firstName: $firstName, lastName: $lastName, emailOrPhone: $emailOrPhone, password: $password, token: $token, phone: $phone, usePhone: $usePhone, useToken: $useToken, timezoneCode: $timezoneCode, gender: $gender, race: $race, ethnicity: $ethnicity)}
`;

const Q_GET_TIMEZONES = `
query timezones {
    timezones {
        id
        displayLong,
        abbreviation
    }
}
`;

const Q_GET_CAREGIVER_BY_ID = `
query getAllCaregivers ($id: String) {
    getAllCaregivers (id: $id) {
        id
        role
        names {
            firstName
            lastName
        }
        demographics {
            gender
            race
            ethnicity
        }
        timezone {
            id
            displayLong
            displayShort
            abbreviation
            offset
        }
        credentials {
            emailOrPhone
        }
        notifications {
            phone
        }
    }
}
`;

export const PMUserCaregiverForm = (props) => {
    
    //console.log("props", props);
    //console.log("content", JSON.parse(props.content));
    const content = props.content ? JSON.parse(props.content) : {context: "create"};
    const [contentID, setContentID] = useState(content.id || "")
    const [editPassword, setEditPassword] = useState(false);
    
    const FORM_CONTEXT = content.context === 'edit' ? 'edit' : 'create';
    
    console.log("FORM_CONTEXT", FORM_CONTEXT);
    
    const minLength = 2;
    const maxLength = 64;
    
    const [formData, setFormData] = useState({
        emailOrPhone: "",
        firstName: "",
        lastName: "",
        phone: "",
        timezoneCode: "ET",
        ethnicity: "001",
        gender: "001",
        race: "001"
    })
    
    const {register, getValues, handleSubmit, formState: {errors}, reset, setValue} = useForm();
    
    const fieldOptionsName = {required: true, minLength, maxLength}
    
    const [updateCaregiverResult, updateCaregiver] = useMutation(M_UPDATE_CAREGIVER);
    const [registerCaregiverResult, registerCaregiver] = useMutation(M_REGISTER_CAREGIVER);
    const [redirect, setRedirect] = useState(<></>);
    
    
    
    useEffect(() => {
        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: Q_GET_CAREGIVER_BY_ID,
                variables: {
                    id: contentID,
                },
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.data) {
                    let d = data.data.getAllCaregivers ? data.data.getAllCaregivers : [];
                    if (d.length === 1) {
                        let caregiver = d[0];
                        console.log(caregiver);
                        setFormData({
                            emailOrPhone: caregiver.credentials.emailOrPhone,
                            firstName: caregiver.names.firstName,
                            lastName: caregiver.names.lastName,
                            phone: caregiver.notifications.phone || "",
                            timezoneCode: caregiver.timezone.abbreviation || "",
                            ethnicity: caregiver.demographics.ethnicity || "",
                            gender: caregiver.demographics.gender || "",
                            race: caregiver.demographics.race || ""
                        });
                    }
                }
            });
    }, [contentID])
    
    useEffect(() => {
        reset(formData);
    }, [reset, formData]);
    
    const onRaceSelected = (e) => {
        setValue("race", e.target.Value);
    }
    
    const onEthnicitySelected = (e) => {
        setValue("ethnicity", e.target.Value);
    }
    
    const onGenderSelected = (e) => {
        setValue("gender", e.target.Value);
    }
    
    const onTimezoneSelected = (e) => {
        console.log(e.target.value);
        setValue("timezoneCode", e.target.Value);
    }
    
    const TimezoneDropdown = () => {
        const [result] = useQuery({
            query: Q_GET_TIMEZONES,
            requestPolicy: "cache-first",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find timezones</p>;
        } else {
            let d = data.timezones ? data.timezones : [];
            if (d.length !== 0) {
                return (
                    <select name="timezones" onChange={onTimezoneSelected} className={"form-control form-control-lg"} {...register("timezoneCode")}>
                        {d.map(tz => (
                            <option key={tz.id} value={tz.abbreviation}>
                                {tz.displayLong}
                            </option>
                        ))}
                    </select>
                );
            }
        }
        return <></>;
    };
    
    const onSubmit = (data) => {
        console.log(data);
        data.token = "";
        data.useToken = false;
        data.usePhone = false;
        
        if (!data.phone) {
            data.phone = null;
        }
        delete data.passwordConfirm;
        registerCaregiver(data).then(result => {
            if (result.data && result.data.registerCaregiver === true) {
                alert("Caregiver Successfully Created");
                setRedirect(<Redirect to={"users"}/>)
            } else {
                alert("Could not create Caregiver. Please ensure the given email has not previously been used")
            }
        });
    }
    
    const validate = () => {
        // console.log(errors);
        handleSubmit(onSubmit)();
    }
    
    const getClassName = (attribute) => {
        let cn = "form-control form-control-lg ";
        
        if (errors[attribute]) {
            cn += "form-error"
        }
        
        return cn;
    }
    
    const getErrorJSX = (attribute, subtype, label) => {
        if (errors[attribute] && errors[attribute].type === subtype) {
            return <span role="alert" className={"right"}>{label}</span>
        }
    }
    
    const topLevelClassNameForDrawer = FORM_CONTEXT === 'edit' ? 'drawer' : "col-12";
    const closeButtonComponent = FORM_CONTEXT === 'edit' ? (
        <button className="btn btn-sm waves-effect waves-light" onClick={() => {
            props.closeFxn();
        }}>
            <i className="mdi mdi-close mdi-24px"/>
        </button>
    ) : (<></>);
    
    return (
        <Card className={topLevelClassNameForDrawer}>
            <Container fluid={true}>
                <Card className="shadow-none">
                    <CardBody>
                        <Row>
                            <Col className="col-1">
                                {closeButtonComponent}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <h4>Core Profile</h4>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <h4>Demographics</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Email</Label>
                                                <input className={getClassName("emailOrPhone")} type="email" placeholder="firstname.lastname@email.com" {...register("emailOrPhone", {required: true, minLength: 10, pattern: /^\S+@\S+$/i})}/>
                                                {getErrorJSX("emailOrPhone", "required", "This field is required")}
                                                {getErrorJSX("emailOrPhone", "minLength", "Must be at least 10 characters")}
                                                {getErrorJSX("emailOrPhone", "pattern", "This does not appear to be a valid email pattern")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Password</Label>
                                                <input className={getClassName("password")} type="password" placeholder="veryStrongPassword123!" {...register("password", {
                                                    required: true,
                                                    minLength: 10,
                                                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i
                                                })}/>
                                                <span role="contentinfo"
                                                      className={"right"}> Must must have<br/>  - 1 UPPERCASE letter<br/>  - 1 lowercase letter<br/>  - 1 digit<br/>  - 1 special character<br/>  - be minimum <b>10</b> characters</span>
                                                {getErrorJSX("password", "required", "This field is required")}
                                                {getErrorJSX("password", "minLength", "Must be at least 10 characters")}
                                                {getErrorJSX("password", "pattern", "Password must have at least one upper case letter, one lower case letter, one digit, one special character, and be a minimum ten characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Confirm Password</Label>
                                                <input className={getClassName("passwordConfirm")} type="password" placeholder="veryStrongPassword123!" {...register("passwordConfirm", {validate: value => value === getValues("password")})}/>
                                                {getErrorJSX("passwordConfirm", "validate", "The passwords do not match")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>First Name</Label>
                                                <input className={getClassName("firstName")} type="text" placeholder="Your First/Given Name" {...register("firstName", fieldOptionsName)} />
                                                {getErrorJSX("firstName", "required", "This field is required")}
                                                {getErrorJSX("firstName", "minLength", "Must be at least 2 characters")}
                                                {getErrorJSX("firstName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Last Name</Label>
                                                <input className={getClassName("lastName")} type="text" placeholder="Your Legal Last Name" {...register("lastName", fieldOptionsName)} />
                                                {getErrorJSX("lastName", "required", "This field is required")}
                                                {getErrorJSX("lastName", "minLength", "Must be at least 2 characters")}
                                                {getErrorJSX("lastName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Phone (Optional)</Label>
                                                <input className={getClassName("phone")} type="text" placeholder="18671234567" {...register("phone", {required: false, minLength: 10, pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i})}/>
                                                {getErrorJSX("phone", "required", "This field is required")}
                                                {getErrorJSX("phone", "minLength", "Must be at least 10 characters")}
                                                {getErrorJSX("phone", "pattern", "This does not appear to be a valid phone pattern")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Timezone</Label>
                                                <TimezoneDropdown/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Sex (Optional)</Label>
                                                <select name="genders" onChange={onGenderSelected} className={"form-control form-control-lg"} {...register("gender", {required: false})}>
                                                    <option key={"001"} value={"001"}>Male</option>
                                                    <option key={"002"} value={"002"}>Female</option>
                                                    <option key={"003"} value={"003"}>Intersex</option>
                                                    <option key={"004"} value={"004"}>Trans-male</option>
                                                    <option key={"005"} value={"005"}>Trans-female</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Race (Optional)</Label>
                                                <select name="races" onChange={onRaceSelected} className={"form-control form-control-lg"} {...register("race", {required: false})}>
                                                    <option key={"001"} value={"001"}>White</option>
                                                    <option key={"002"} value={"002"}>Black or African American</option>
                                                    <option key={"003"} value={"003"}>American Indian and Alaska Native</option>
                                                    <option key={"004"} value={"004"}>Asian</option>
                                                    <option key={"005"} value={"005"}>Native Hawaiian and Other Pacific Islander</option>
                                                    <option key={"006"} value={"006"}>Two or More Races</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Ethnicity (Optional)</Label>
                                                <select name="ethnicities" onChange={onEthnicitySelected} className={"form-control form-control-lg"} {...register("ethnicity", {required: false})}>
                                                    <option key={"001"} value={"001"}>Hispanic or Latino</option>
                                                    <option key={"002"} value={"002"}>Non-Hispanic or Latino</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col className="col-4">
                                &nbsp;
                            </Col>
                            <Col className="col-2">
                                {/*{editButtonComponent}*/}
                            </Col>
                            <Col className="col-1">
                                <Button className="form-control-lg btn-info" onClick={validate}>Submit</Button>
                                {redirect}
                            </Col>
                            <Col className="col-5">
                                &nbsp;
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Card>
    );
};
