import React, { useState }                                    from "react";
import { useForm }                                            from "react-hook-form";
import { Redirect }                                           from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { useMutation }                                        from "urql";
import "../../assets/scss/custom.scss"


const M_REGISTER_PATIENT = `
mutation registerPatient($firstName: String!, $lastName: String!, $alias: String!, $coding: String!, $colorCode: String!, $yearOfBirth: Float!, $monthOfBirth: Float!, $gender: String, $ethnicity: String, $race: String) {
  registerPatient(firstName: $firstName, lastName: $lastName, alias: $alias, coding: $coding, colorCode: $colorCode, yearOfBirth: $yearOfBirth, monthOfBirth: $monthOfBirth, gender: $gender, ethnicity: $ethnicity, race: $race)}
`;

export const PMUserPatientForm = (props) => {
    
    const content = props.content ? JSON.parse(props.content) : {context: "create"};
    const FORM_CONTEXT = content.context === 'edit' ? 'edit' : 'create';
    
    console.log("FORM_CONTEXT", FORM_CONTEXT);
    
    const minLength = 4;
    const maxLength = 64;
    
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    
    const fieldOptionsName = {required: true, minLength, maxLength}
    
    const [registerPatientResult, registerPatient] = useMutation(M_REGISTER_PATIENT);
    const [redirect, setRedirect] = useState(<></>);
    
    const onColorCodeSelected = (e) => {
        console.log(e.target.value);
        setValue("colorCode", e.target.Value);
    }
    
    const onSubmit = (data) => {
        console.log(data);
        let coding = data.firstName.concat(data.lastName, data.alias);
        data.coding = coding.toLowerCase().replace(/\s/g, '');
        registerPatient(data).then(result => {
            if (result.data && result.data.registerPatient === true) {
                alert("Patient Successfully Created");
                setRedirect(<Redirect to={"users"}/>)
            } else {
                alert("Could not create Patient. Please ensure alias was not previously used")
            }
        });
    }
    
    const validate = () => {
        console.log(errors);
        handleSubmit(onSubmit)();
    }
    
    const getClassName = (attribute) => {
        let cn = "form-control form-control-lg ";
        
        if (errors[attribute]) {
            cn += "form-error"
        }
        
        return cn;
    }
    
    const getErrorJSX = (attribute, subtype, label) => {
        if (errors[attribute] && errors[attribute].type === subtype) {
            return <span role="alert" className={"right"}>{label}</span>
        }
    }
    
    const getYearOptions = () => {
        const currentYear = (new Date()).getFullYear();
        const options = [];
        
        for (let i = 0; i <= 35; i++) {
            const year = currentYear - i;
            options.push(<option value={year} key={i}>{year}</option>);
        }
        
        return options;
    }
    
    const getMonthOptions = () => {
        const currentYear = (new Date()).getFullYear();
        const options = [];
        
        for (let i = 0; i <= 11; i++) {
            options.push(<option value={i} key={i}>{(new Date(currentYear, i)).toLocaleString('default', {month: 'long'})}</option>);
        }
        
        return options;
    }
    
    const topLevelClassNameForDrawer = FORM_CONTEXT === 'edit' ? 'drawer' : "col-12";
    const closeButtonComponent = FORM_CONTEXT === 'edit' ? (
        <button className="btn btn-sm waves-effect waves-light" onClick={() => {
            props.closeFxn();
        }}>
            <i className="mdi mdi-close mdi-24px"/>
        </button>
    ) : (<></>);
    
    return (
        <Card className={topLevelClassNameForDrawer}>
            <Container fluid={true}>
                <Card className="shadow-none">
                    <CardBody>
                        <Row>
                            <Col className="col-1">
                                {closeButtonComponent}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <h4>Core Profile</h4>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <h4>Demographics</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>First Name</Label>
                                                <input className={getClassName("firstName")} type="text" placeholder="First/Given Name" {...register("firstName", fieldOptionsName)} />
                                                {getErrorJSX("firstName", "required", "This field is required")}
                                                {getErrorJSX("firstName", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("firstName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Last Name</Label>
                                                <input className={getClassName("lastName")} type="text" placeholder="Legal Last Name" {...register("lastName", fieldOptionsName)} />
                                                {getErrorJSX("lastName", "required", "This field is required")}
                                                {getErrorJSX("lastName", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("lastName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Alias</Label>
                                                <input className={getClassName("alias")} type="text" placeholder="Nickname/Alias" {...register("alias", {...fieldOptionsName})} />
                                                {getErrorJSX("alias", "required", "This field is required")}
                                                {getErrorJSX("alias", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("alias", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Month of Birth</Label>
                                                <select name="monthOfBirth" onChange={(e) => console.log(e)} className={"form-control form-control-lg"} {...register("monthOfBirth", {required: true, valueAsNumber: true})}>
                                                    {getMonthOptions()}
                                                    {getErrorJSX("monthOfBirth", "required", "This field is required")}
                                                    {getErrorJSX("monthOfBirth", "minLength", "Must be at least 4 characters")}
                                                    {getErrorJSX("monthOfBirth", "maxLength", "Must not exceed 64 characters")}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Year of Birth</Label>
                                                <select name="yearOfBirth" onChange={(e) => console.log(e)} className={"form-control form-control-lg"} {...register("yearOfBirth", {required: true, valueAsNumber: true})}>
                                                    {getYearOptions()}
                                                    {getErrorJSX("yearOfBirth", "required", "This field is required")}
                                                    {getErrorJSX("yearOfBirth", "minLength", "Must be at least 4 characters")}
                                                    {getErrorJSX("yearOfBirth", "maxLength", "Must not exceed 64 characters")}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Color Code</Label>
                                                <select name="colorCode" onChange={onColorCodeSelected} className={"form-control form-control-lg"} {...register("colorCode", {required: true})}>
                                                    <option key={"001"} value={"#7FFFD4"}>Green</option>
                                                    <option key={"002"} value={"#E0FFFF"}>Blue</option>
                                                    <option key={"003"} value={"#DDA0DD"}>Pink</option>
                                                    <option key={"004"} value={"#F5DEB3"}>Brown</option>
                                                    <option key={"005"} value={"#DCDCDC"}>Grey</option>
                                                </select>
                                                {getErrorJSX("alias", "required", "This field is required")}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Sex (Optional)</Label>
                                                <select name="gender" onChange={(e) => console.log(e)} className={"form-control form-control-lg"} {...register("gender", {required: false})}>
                                                    <option key={"001"} value={"001"}>Male</option>
                                                    <option key={"002"} value={"002"}>Female</option>
                                                    <option key={"003"} value={"003"}>Intersex</option>
                                                    <option key={"004"} value={"004"}>Trans-male</option>
                                                    <option key={"005"} value={"005"}>Trans-female</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                                {getErrorJSX("alias", "required", "This field is required")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Race (Optional)</Label>
                                                <select name="race" onChange={(e) => console.log(e)} className={"form-control form-control-lg"} {...register("race", {required: false})}>
                                                    <option key={"001"} value={"001"}>White</option>
                                                    <option key={"002"} value={"002"}>Black or African American</option>
                                                    <option key={"003"} value={"003"}>American Indian and Alaska Native</option>
                                                    <option key={"004"} value={"004"}>Asian</option>
                                                    <option key={"005"} value={"005"}>Native Hawaiian and Other Pacific Islander</option>
                                                    <option key={"006"} value={"006"}>Two or More Races</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                                {getErrorJSX("alias", "required", "This field is required")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Ethnicity (Optional)</Label>
                                                <select name="ethnicity" onChange={(e) => console.log(e)} className={"form-control form-control-lg"} {...register("ethnicity", {required: false})}>
                                                    <option key={"001"} value={"001"}>Hispanic or Latino</option>
                                                    <option key={"002"} value={"002"}>Non-Hispanic or Latino</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                                {getErrorJSX("alias", "required", "This field is required")}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col className="col-4">
                                &nbsp;
                            </Col>
                            <Col className="col-2">
                                {/*{editButtonComponent}*/}
                            </Col>
                            <Col className="col-1">
                                <Button className="form-control-lg btn-info" onClick={validate}>Submit</Button>
                                {redirect}
                            </Col>
                            <Col className="col-5">
                                &nbsp;
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Card>
    );
};
