import React                   from "react";
import { Redirect }            from "react-router-dom";
import PMUserFormContainer     from "../modules/users/PMUserFormContainer";
import PMUsersArchiveDataTable from "../modules/users/PMUsersArchiveDataTable";
import PMUsersDataTable        from "../modules/users/PMUsersDataTable";

import Login      from "../pages/Authentication/Login";
import Logout     from "../pages/Authentication/Logout";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import Login1     from "../pages/AuthenticationInner/Login";
import Register1  from "../pages/AuthenticationInner/Register";
import { AdminPortal } from '@frontegg/react';

import Calendar from "../pages/Calendar/index";

// Charts

// // Pages Component
import Chat from "../pages/Chat/Chat";

//Contacts
import ContactsGrid    from "../pages/Contacts/contacts-grid";
import ContactsList    from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";

// Profile
import { UserProfile } from "../pages/Contacts/user-profile";
//Crypto

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// //Ecommerce Pages
import FormAdvanced from "../pages/Forms/FormAdvanced";

// Forms
import FormElements      from "../pages/Forms/FormElements";
import SpecificationForm from "../pages/Forms/SpecificationForm";

//Icons
import InvoiceDetail             from "../pages/Invoices/invoices-detail";

//Email
//Invoices
import InvoicesList                     from "../pages/Invoices/invoices-list";

//Tables
// import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables                  from "../pages/Tables/DatatableTables";
import PMMapFormSkillSpec               from "../pages/Tables/PMMapFormSkillSpec";
import PMMapFormSkillUser               from "../pages/Tables/PMMapFormSkillUser";
import PMMapFormSpecUser                from "../pages/Tables/PMMapFormSpecUser";
import { PMPromptForm }                 from "../pages/Tables/PMPromptForm";
import PMPromptsDataTable               from "../pages/Tables/PMPromptsDataTable";
import PMPromptsArchiveDataTable               from "../pages/Tables/PMPromptsArchiveDataTable";
import PMSpecificationsArchiveDataTable from "../pages/Tables/PMSpecificationsArchiveDataTable";
import PMSpecificationsDataTable        from "../pages/Tables/PMSpecificationsDataTable";
import { PMTaskForm }                   from "../pages/Tables/PMTaskForm";
import PMTasksDataTable                 from "../pages/Tables/PMTasksDataTable";
import TasksCreate                      from "../pages/Tasks/tasks-create";


//Ui
// import PagesPricing from "../pages/Utility/pages-pricing";
import Pages403                  from "../pages/Utility/pages-403";
import Pages404                  from "../pages/Utility/pages-404";
import Pages500                  from "../pages/Utility/pages-500";
import PagesFaqs                 from "../pages/Utility/pages-faqs";

//Pages
import PagesMaintenance          from "../pages/Utility/pages-maintenance";

const userRoutes = [
    
    {path: "/dashboard", component: Dashboard},

    
    //chat
    {path: "/chat", component: Chat},
    
    // //calendar
    {path: "/calendar", component: Calendar},
    
    // //profile
    {path: "/profile", component: UserProfile},
    
    //Invoices
    {path: "/invoices-list", component: InvoicesList},
    {path: "/invoices-detail", component: InvoiceDetail},
    
    // Tasks
    {path: "/tasks-create", component: TasksCreate},
    
    // Contacts
    {path: "/contacts-grid", component: ContactsGrid},
    {path: "/contacts-list", component: ContactsList},
    {path: "/contacts-profile", component: ContactsProfile},
    
    //Charts
    // {path: "/apex-charts", component: ChartApex},
    // {path: "/chartist-charts", component: ChartistChart},
    // {path: "/chartjs-charts", component: ChartjsChart},
    // {path: "/e-charts", component: EChart},
    // {path: "/sparkline-charts", component: SparklineChart},
    // {path: "/tui-charts", component: ToastUIChart},
    // {path: "/charts-knob", component: ChartsKnob},
    
    // Tables
    // { path: "/tables-basic", component: BasicTables },
    {path: "/tables-datatable", component: DatatableTables},
    {path: "/skills", component: PMSpecificationsDataTable},
    {path: "/spec-archive", component: PMSpecificationsArchiveDataTable},
    {path: "/add-skill", component: SpecificationForm},
    {path: "/associate-skill-spec", component: PMMapFormSkillSpec},
    {path: "/associate-skill-user", component: PMMapFormSkillUser},
    
    
    {path: "/antecedents", component: PMSpecificationsDataTable},
    {path: "/behaviours", component: PMSpecificationsDataTable},
    {path: "/consequences", component: PMSpecificationsDataTable},
    {path: "/add-specification", component: SpecificationForm},
    {path: "/associate-spec-user", component: PMMapFormSpecUser},
    
    // {path: "/skills", component: PMSkillsDataTable},
    {path: "/users", component: PMUsersDataTable},
    {path: "/users-archive", component: PMUsersArchiveDataTable},
    {path: "/add-user", component: PMUserFormContainer},
    {path: "/user-profile", component: UserProfile},
    
    {path: "/prompts", component: PMPromptsDataTable},
    {path: "/prompts-archive", component: PMPromptsArchiveDataTable},
    {path: "/add-prompt", component: PMPromptForm},
    {path: "/tasks", component: PMTasksDataTable},
    {path: "/add-task", component: PMTaskForm},
    {path: "/task-schedule", component: Calendar},
    
    
    // Forms
    {path: "/form-elements", component: FormElements},
    {path: "/form-advanced", component: FormAdvanced},
    // Ui
    // {path: "/ui-alerts", component: UiAlert},
    // {path: "/ui-buttons", component: UiButtons},
    // {path: "/ui-cards", component: UiCards},
    // {path: "/ui-carousel", component: UiCarousel},
    // {path: "/ui-colors", component: UiColors},
    // {path: "/ui-dropdowns", component: UiDropdown},
    // {path: "/ui-general", component: UiGeneral},
    // {path: "/ui-grid", component: UiGrid},
    // {path: "/ui-images", component: UiImages},
    // {path: "/ui-lightbox", component: UiLightbox},
    // {path: "/ui-modals", component: UiModal},
    // {path: "/ui-progressbars", component: UiProgressbar},
    // {path: "/ui-sweet-alert", component: UiSweetAlert},
    // {path: "/ui-tabs-accordions", component: UiTabsAccordions},
    // {path: "/ui-typography", component: UiTypography},
    // {path: "/ui-video", component: UiVideo},
    // {path: "/ui-session-timeout", component: UiSessionTimeout},
    // {path: "/ui-rating", component: UiRating},
    // {path: "/ui-rangeslider", component: UiRangeSlider},
    // {path: "/ui-notifications", component: UiNotifications},
    // {path: "/ui-image-cropper", component: UiImageCropper},
    
    //Utility
    {path: "/pages-faqs", component: PagesFaqs},
    
    // this route should be at the end of all other routes
    {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>}
];

const authRoutes = [
    {path: "/logout", component: Logout},
    {path: "/login", component: Login},
    {path: "/sign-up", component: Login},
    {path: "/admin-portal", component: AdminPortal},
    {path: "/account/sign-up", component: Login},
    {path: "/account/login", component: Login},
    {path: "/account/logout", component: Login},
    {path: "/pages-maintenance", component: PagesMaintenance},
    {path: "/pages-403", component: Pages403},
    {path: "/pages-404", component: Pages404},
    {path: "/pages-404", component: Pages404},
    {path: "/pages-500", component: Pages500},
    
    // Authentication Inner
    {path: "/pages-login", component: Login1},
    {path: "/pages-register", component: Register1},
    {path: "/auth-lock-screen", component: LockScreen}
];

export { userRoutes, authRoutes };
