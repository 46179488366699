import React, { useEffect, useState }                                                                                                              from "react";
import { Redirect }                                                                                                                                from "react-router-dom";
import BarLoader                                                                                                                                   from "react-spinners/BarLoader";
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row }                                                                    from "reactstrap";
import { useMutation, useQuery }                                                                                                                   from "urql";
import voca                                                                                                                                        from 'voca';
import { FORM_CONTEXT_SKILL_CREATE, FORM_CONTEXT_SPEC_CREATE, FORM_CONTEXT_SPEC_EDIT, SPEC_TYPE_ANT, SPEC_TYPE_BHV, SPEC_TYPE_CSQ, SPEC_TYPE_SKL } from "../../components/Common/Constants";
import { initialFormState }                                                                                                                        from "../Forms/SpecificationForm";


const AttrValidationNode = (props) => {
    const styles = {float: "right", marginTop: "8px", fontSize: "11px"};
    const infoStyles = {marginTop: "8px", display: "inline-block", fontSize: "11px"};
    let displayType = voca.titleCase(props.type);
    let info = <></>
    let classNameStr = "badgecount badge";
    if (props.count < 4) {
        info = <span style={infoStyles}>{displayType} Must Between {props.min} & {props.max} Characters Long</span>;
        classNameStr += " badge-danger"
    } else if (props.count >= 4 && props.count < Math.floor(props.max * .75)) {
        classNameStr += " badge-success"
    } else if (props.count >= Math.floor(props.max * .8) && props.count < Math.floor(props.max * .88)) {
        classNameStr += " badge-warning"
    } else {
        classNameStr += " badge-danger"
    }
    return (
        <>
            {info}
            <span style={styles} className={classNameStr}>
                {props.count} / {props.max}{" "}
            </span>
        </>
    );
}

const POLLING_INTERVAL = 5 * 60 * 25;
const Q_GET_SPEC_BY_KEY = `
query getSpecificationByKey($key: String!) {
    getSpecificationByKey(key: $key) {
        id
        coding {
            key
        }
    }
}
`;

const M_REGISTER_SPEC = `
mutation registerSpecification($t: String!, $ck: String!, $cd: String!, $e: Boolean!, $d: String!, $w: Float!) {
  registerSpecification(type: $t, codingKey: $ck, codingDisplay: $cd, enabled: $e, description: $d, weight: $w)}
`;


const M_UPDATE_SPEC = `
mutation updateSpecification($i: String!, $ck: String!, $cd: String!, $e: Boolean!, $d: String!, $w: Float!) {
  updateSpecification(id: $i, codingKey: $ck, codingDisplay: $cd, enabled: $e, description: $d, weight: $w)}
`;

const M_UPDATE_SPEC_INT = `
mutation updateSpecificationInteraction($i: String!, $key: String!, $cd: String!, $d: String!) {
  updateSpecificationInteraction(id: $i, key: $key, display: $cd, description: $d)}
`;

export const M_DISABLE_SPEC = `
mutation  disableSpecification($i: String!) {
  disableSpecification(id: $i)}
`;

export const M_ENABLE_SPEC = `
mutation  enableSpecification($i: String!) {
  enableSpecification(id: $i)}
`;

export const M_DELETE_SPEC = `
mutation  deleteSpecification($i: String!) {
  deleteSpecification(id: $i)}
`;

export const PMSpecificationsForm = (props) => {
    
    const [content, setContent] = useState(JSON.parse(props.content));
    console.log("content", content);
    let {associations, context} = content;
    
    const [id, setId] = useState(content.id);
    const [key, setKey] = useState(content.key);
    const [display, setDisplay] = useState(content.display);
    const [description, setDescription] = useState(content.description);
    const [weight, setWeight] = useState(content.weight);
    const [type, setType] = useState(content.type);
    
    const contextDisplayLabel = context === FORM_CONTEXT_SPEC_EDIT ? 'Edit' : 'Create';
    const [mainTitle, setMainTitle] = useState(voca.titleCase(contextDisplayLabel) + " " + voca.titleCase(type));
    
    const [showBadgeAttrKey, setShowBadgeAttrKey] = useState(false);
    const [currCountAttrKey, setCurrCountAttrKey] = useState(0);
    
    const [showBadgeAttrDisplay, setShowBadgeAttrDisplay] = useState(false);
    const [currCountAttrDisplay, setCurrCountAttrDisplay] = useState(0);
    
    const [showBadgeAttrDescription, setShowBadgeAttrDescription] = useState(false);
    const [currCountAttrDescription, setCurrCountAttrDescription] = useState(0);
    
    const [updateSpecResult, updateSpec] = useMutation(M_UPDATE_SPEC);
    const [updateSpecIntResult, updateSpecInt] = useMutation(M_UPDATE_SPEC_INT);
    const [registerSpecResult, registerSpec] = useMutation(M_REGISTER_SPEC);
    
    const [redirect, setRedirect] = useState(<></>);
    
    
    useEffect(() => {
        setMainTitle(voca.titleCase(contextDisplayLabel) + " " + voca.titleCase(type));
    }, [type])
    
    useEffect(() => {
        setId(content.id);
        setKey(content.key);
        setDisplay(content.display);
        setDescription(content.description);
        setWeight(content.weight);
        setType(content.type);
        setShowBadgeAttrKey(false);
        setCurrCountAttrKey(0);
        setShowBadgeAttrDisplay(false);
        setCurrCountAttrDisplay(0);
        setShowBadgeAttrDescription(false);
        setCurrCountAttrDescription(0);
    }, [content]);
    
    const resetForm = () => {
        let t = initialFormState;
        if (context === FORM_CONTEXT_SKILL_CREATE) {
            t = {...initialFormState, type: SPEC_TYPE_SKL, context: FORM_CONTEXT_SKILL_CREATE};
        }
        setContent(t);
        setId(content.id);
        setKey(content.key);
        setDisplay(content.display);
        setDescription(content.description);
        setWeight(content.weight);
        setType(content.type);
        setShowBadgeAttrKey(false);
        setCurrCountAttrKey(0);
        setShowBadgeAttrDisplay(false);
        setCurrCountAttrDisplay(0);
        setShowBadgeAttrDescription(false);
        setCurrCountAttrDescription(0);
    }
    
    const CodingKeyValidation = () => {
        const [result] = useQuery({
            query: Q_GET_SPEC_BY_KEY,
            requestPolicy: "network-only",
            pollInterval: POLLING_INTERVAL,
            variables: {key}
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Oh no... {error.message}</p>;
        } else {
            let d = data.getSpecificationByKey;
            if (d.length !== 0) {
                let _i = d[0].id, _k = d[0].coding.key;
                if (_i === id && _k === key) {
                    return <></>;
                } else {
                    return <span style={{marginTop: "8px", display: "inline-block", fontSize: "11px", color: "red"}}>Key {_k} is already used by Specification with ID {_i}</span>;
                }
            }
        }
        return <></>;
    };
    
    
    const onSubmit = (navigateAway = false) => {
        let variables = {
            ck: key || false,
            cd: display || '',
            e: true,
            d: description || '',
            w: weight || 1,
        };
        
        if (context === FORM_CONTEXT_SPEC_CREATE || context === FORM_CONTEXT_SKILL_CREATE) {
            let t = type;
            variables = {t, ...variables};
            registerSpec(variables).then(result => {
                console.log(result);
                if (result.data.registerSpecification === true) {
                    alert("saved!");
                    if (navigateAway) {
                        let path = `/${type}s`;
                        setRedirect(<Redirect to={path}/>);
                    } else {
                        resetForm();
                    }
                }
                resetForm();
            })
        } else {
            let i = id || false;
            variables = {i, ...variables}
            
            if (type === "prompt") {
                updateSpecInt({
                    i: id,
                    key: key,
                    cd: display || '',
                    d: description || '',
                }).then(result => {
                    console.log(result);
                    if (result.data.updateSpecificationInteraction === true) {
                        alert("Updated Prompt");
                    }
                    setRedirect(<></>);
                });
            } else {
                updateSpec(variables).then(result => {
                    console.log(result);
                    if (result.data.updateSpecification === true) {
                        alert("Updated A/B/C!");
                    }
                    setRedirect(<></>);
                });
            }
        }
    };
    
    const attrKeyChange = (event) => {
        let count = event.target.value.length;
        if (count > 0) {
            setShowBadgeAttrKey(true);
        } else {
            setShowBadgeAttrKey(false);
        }
        setCurrCountAttrKey(event.target.value.length);
        setKey(event.target.value);
    }
    
    const attrDisplayChange = (event) => {
        let count = event.target.value.length;
        if (count > 0) {
            setShowBadgeAttrDisplay(true);
        } else {
            setShowBadgeAttrDisplay(false);
        }
        setCurrCountAttrDisplay(event.target.value.length);
        setDisplay(event.target.value);
    }
    
    const attrDescriptionChange = (event) => {
        let count = event.target.value.length;
        if (count > 0) {
            setShowBadgeAttrDescription(true);
        } else {
            setShowBadgeAttrDescription(false);
        }
        setCurrCountAttrDescription(event.target.value.length);
        setDescription(event.target.value);
    }
    
    const topLevelClassNameForDrawer = context === FORM_CONTEXT_SPEC_EDIT ? 'drawer' : '';
    const closeButtonComponent = context === FORM_CONTEXT_SPEC_EDIT ? (
        <button className="btn btn-sm waves-effect waves-light" onClick={() => {
            props.closeFxn();
        }}>
            <i className="mdi mdi-close mdi-24px"/>
        </button>
    ) : (<></>)
    
    let buttons = <Button className="form-control-lg btn-info" onClick={() => onSubmit()}>Update</Button>;
    if (context === FORM_CONTEXT_SPEC_CREATE || context === FORM_CONTEXT_SKILL_CREATE) {
        buttons =
            <>
                <Button className="form-control-lg btn-info" onClick={() => onSubmit(true)}>Create</Button> &nbsp;
                <Button className="form-control-lg btn-info" onClick={() => onSubmit()}>Create and Add Another</Button>
                {redirect}
            </>
        ;
    }
    
    return (
        <Card className={topLevelClassNameForDrawer}>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card className="shadow-none">
                            <CardBody>
                                <Row>
                                    <Col className="col-11">
                                        <CardTitle>{mainTitle}</CardTitle>
                                    </Col>
                                    <Col className="col-1">
                                        {closeButtonComponent}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>ID</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="text"
                                                defaultValue={id}
                                                name="id"
                                                id="id"
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Coding Key</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="text"
                                                minLength="4"
                                                maxLength="64"
                                                placeholder="Enter Coding Key, e.g BHV.1"
                                                defaultValue={key}
                                                onChange={(e) => {
                                                    attrKeyChange(e);
                                                }}
                                                name="key"
                                                id="key"
                                            />
                                            {showBadgeAttrKey ?
                                                <AttrValidationNode count={currCountAttrKey} min={4} max={64} type="Coding Key"/> : null}
                                            <CodingKeyValidation/>
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Coding Display</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="textarea"
                                                minLength="4"
                                                maxLength="4096"
                                                placeholder="Enter the Mobile Display"
                                                defaultValue={display}
                                                onChange={(e) => {
                                                    attrDisplayChange(e);
                                                }}
                                                name="display"
                                                id="display"
                                            />
                                            {showBadgeAttrDisplay ?
                                                <AttrValidationNode count={currCountAttrDisplay} min={4} max={4096} type="Display"/> : null}
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Coding Description</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="textarea"
                                                minLength="4"
                                                maxLength="4096"
                                                placeholder="Enter a description..."
                                                defaultValue={description}
                                                onChange={(e) => {
                                                    attrDescriptionChange(e);
                                                }}
                                                name="description"
                                                id="description"
                                            />
                                            {showBadgeAttrDescription ?
                                                <AttrValidationNode count={currCountAttrDescription} min={4} max={4096}
                                                                    type="Description"/> : null}
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label for="typeSelect">Type{context == FORM_CONTEXT_SPEC_EDIT ? ' (Not Editable)' : ''}</Label>
                                            <Input className="form-control-lg" type="select" name="typeSelect" id="typeSelect"
                                                   readOnly={context == FORM_CONTEXT_SPEC_EDIT} disabled={context == FORM_CONTEXT_SPEC_EDIT}
                                                   onChange={(event) => {
                                                       setType(event.target.value)
                                                   }}>
                                                
                                                {content.type === 'skill' ?
                                                    <option key="skill" selected={SPEC_TYPE_SKL === type}
                                                            value={SPEC_TYPE_SKL}>Skill</option> : null}
                                                
                                                {context !== FORM_CONTEXT_SKILL_CREATE ?
                                                    <option key="antecedent" selected={SPEC_TYPE_ANT === type}
                                                            value={SPEC_TYPE_ANT}>Antecedent</option> : null}
                                                {context !== FORM_CONTEXT_SKILL_CREATE ?
                                                    <option key="behaviour" selected={SPEC_TYPE_BHV === type}
                                                            value={SPEC_TYPE_BHV}>Behaviour</option> : null}
                                                {context !== FORM_CONTEXT_SKILL_CREATE ?
                                                    <option key="consequence" selected={SPEC_TYPE_CSQ === type}
                                                            value={SPEC_TYPE_CSQ}>Consequence</option> : null}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Weight</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="number"
                                                defaultValue={weight}
                                                onChange={(e) => {
                                                    setWeight(parseInt(e.target.value));
                                                }}
                                                name="weight"
                                                id="weight"
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                
                                <Row>&nbsp;</Row>
                                
                                {buttons}
                            
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
};
