import React, { useState }                                                    from 'react';
import { Link }                                                               from "react-router-dom";
import BarLoader                                                              from 'react-spinners/BarLoader';
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useMutation, useQuery }                                              from "urql";
import voca                                                                   from 'voca';
import Breadcrumbs                                                            from '../../components/Common/Breadcrumb';
import { CustomDataTable, SelectColumnFilter } from "./../../pages/Tables/CustomDataTable";
import "./../../pages/Tables/datatables.scss";
import { PMUserCaregiverForm }                 from "./PMUserCaregiverForm";
import { PMUserPatientForm }                   from "./PMUserPatientForm";
import { PMUserPhysicianForm }                 from "./PMUserPhysicianForm";

const QUERY_GET_DISABLED_USERS = `
query {
    getDisabledUsers {
        id
        firstName
        lastName
        role
        alias
        emailOrPhone
        usePhone
        useToken
    }
}
`;

const POLLING_INTERVAL_MINUTES = 5;
const POLLING_INTERVAL_MILLISECONDS = POLLING_INTERVAL_MINUTES * 60 * 100 * 10;// remove the * 10 when you fix the filter issue

const M_DELETE_PHYSICIAN = `
mutation  deletePhysician($i: String!) {
  deletePhysician(id: $i)}
`;

const M_DELETE_CAREGIVER = `
mutation  deleteCaregiver($i: String!) {
  deleteCaregiver(id: $i)}
`;

const M_DELETE_PATIENT = `
mutation  deletePatient($i: String!) {
  deletePatient(id: $i)}
`;

const M_ENABLE_PHYSICIAN = `
mutation  enablePhysician($i: String!) {
  enablePhysician(id: $i)}
`;

const M_ENABLE_CAREGIVER = `
mutation  enableCaregiver($i: String!) {
  enableCaregiver(id: $i)}
`;

const M_ENABLE_PATIENT = `
mutation  enablePatient($i: String!) {
  enablePatient(id: $i)}
`;

const PMUsersArchiveDataTable = (props) => {
    
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const [userTypeForm, setUserTypeForm] = useState(-1);
    
    const [modal, setModal] = useState(false);
    const [userToDeleteDisplay, setUserToDeleteDisplay] = useState('');
    const [userToDeleteID, setUserToDeleteID] = useState('');
    const [userToDeleteType, setUserToDeleteType] = useState('');
    
    const [enableModal, setEnableModal] = useState(false);
    const [userToEnableDisplay, setUserToEnableDisplay] = useState('');
    const [userToEnableID, setUserToEnableID] = useState('');
    const [userToEnableType, setUserToEnableType] = useState('');
    
    const [deleteCaregiverResult, deleteCaregiver] = useMutation(M_DELETE_CAREGIVER);
    const [deletePhysicianResult, deletePhysician] = useMutation(M_DELETE_PHYSICIAN);
    const [deletePatientResult, deletePatient] = useMutation(M_DELETE_PATIENT);
    
    const [enableCaregiverResult, enableCaregiver] = useMutation(M_ENABLE_CAREGIVER);
    const [enablePhysicianResult, enablePhysician] = useMutation(M_ENABLE_PHYSICIAN);
    const [enablePatientResult, enablePatient] = useMutation(M_ENABLE_PATIENT);
    
    
    let tableData = [];
    
    const DataTable = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_DISABLED_USERS,
            requestPolicy: "cache-and-network",
            pollInterval: POLLING_INTERVAL_MILLISECONDS
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        if (fetching) {
            return (
                <>
                    <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
                    <CustomDataTable columns={columns} data={tableData}/>
                </>
            );
        } else if (error) {
            return (
                <>
                    <p>Oh no... {error.message}</p>
                    <CustomDataTable columns={columns} data={tableData}/>
                </>
            );
        } else {
            tableData = [];
            data.getDisabledUsers.map(user => (
                tableData.push({
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    emailOrPhone: user.emailOrPhone,
                    usePhone: user.usePhone,
                    useToken: user.useToken,
                    alias: voca.lowerCase(user.alias),
                    role: voca.capitalize(user.role),
                    edit: '',
                    delete: ''
                })
            ));
            return (
                <>
                    <CustomDataTable columns={columns} data={tableData}/>
                </>
            );
        }
    };
    
    const enableRowHandler = (data, shouldShow) => {
        if (shouldShow) {
            setUserToEnableID(data.id);
            setUserToEnableDisplay(data.firstName + " " + data.lastName + " (" + data.role + ")");
            setUserToEnableType(voca.lowerCase(data.role))
        } else {
            setUserToEnableID('');
            setUserToEnableDisplay('');
            setUserToEnableType('')
        }
        setEnableModal(shouldShow);
    };
    
    const enableHandler = (id, type) => {
        if (voca.lowerCase(type) === 'provider') {
            enablePhysician({ i: id}).then(()=> {
                setUserToEnableID('');
                setUserToEnableDisplay('');
                setUserToEnableType('')
                setEnableModal(false);
            })
        } else if (voca.lowerCase(type) === 'caregiver') {
            enableCaregiver({ i: id}).then(()=> {
                setUserToEnableID('');
                setUserToEnableDisplay('');
                setUserToEnableType('')
                setEnableModal(false);
            })
        } else if (voca.lowerCase(type) === 'patient') {
            enablePatient({ i: id}).then(()=> {
                setUserToEnableID('');
                setUserToEnableDisplay('');
                setUserToEnableType('')
                setEnableModal(false);
            })
        } else {
            console.log("Could not determine the type of user to do a enable.")
            setEnableModal(false);
        }
    };
    
    
    const deleteRowHandler = (data, shouldShow) => {
        if (shouldShow) {
            setUserToDeleteID(data.id);
            setUserToDeleteDisplay(data.firstName + " " + data.lastName + " (" + data.role + ")");
            setUserToDeleteType(voca.lowerCase(data.role))
        } else {
            setUserToDeleteID('');
            setUserToDeleteDisplay('');
            setUserToDeleteType('')
        }
        setModal(shouldShow);
    };
    
    const deleteHandler = (id, type) => {
        if (voca.lowerCase(type) === 'provider') {
            deletePhysician({ i: id}).then(()=> {
                setUserToDeleteID('');
                setUserToDeleteDisplay('');
                setUserToDeleteType('');
                setModal(false);
            })
        } else if (voca.lowerCase(type) === 'caregiver') {
            deleteCaregiver({ i: id}).then(()=> {
                setUserToDeleteID('');
                setUserToDeleteDisplay('');
                setUserToDeleteType('');
                setModal(false);
            })
        } else if (voca.lowerCase(type) === 'patient') {
            deletePatient({ i: id}).then(()=> {
                setUserToDeleteID('');
                setUserToDeleteDisplay('');
                setUserToDeleteType('');
                setModal(false);
            })
        } else {
            console.log("Could not determine the type of user to do a delete.")
            setModal(false);
        }
    };
    
    
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Contact',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                    },
                    {
                        Header: 'Email or Phone',
                        accessor: 'emailOrPhone',
                    },
                    {
                        Header: 'Alias',
                        accessor: 'alias',
                    },
                    {
                        Header: 'Role',
                        accessor: 'role',
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                    },
                ]
            },
            {
                Header: 'Actions',
                columns: [
                    {
                        Header: '',
                        accessor: 'edit',
                        Cell: ({cell: {value, ...props}}) => {
                            return (
                                <React.Fragment>
                                    {/*<Row>*/}
                                    {/*    <Col className="col-2 col-sm">*/}
                                    <button className="btn btn-success btn-sm waves-effect waves-light" onClick={() => {
                                        enableRowHandler(props.row.original, true);
                                    }}><i className="mdi mdi-account-edit mdi-18px"/> Enable User
                                    </button>
                                    {/*</Col>*/}
                                    {/*<Col className="col-2 col-sm">*/}
                                    &nbsp;
                                    
                                    <button className="btn btn-danger btn-sm waves-effect waves-light" onClick={() => {
                                        deleteRowHandler(props.row.original, true);
                                    }}><i className="mdi mdi-trash-can mdi-18px"/> Delete User
                                    </button>
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                </React.Fragment>
                            )
                        },
                        Filter: () => {
                            return null;
                        }
                    }
                ],
                minWidth: 175,
            }
        ],
        []
    );
    
    
    let drawerComponent = '';
    let drawerOverlayComponent = '';
    if (showDrawer) {
        drawerOverlayComponent = (
            <div className="drawer-overlay"/>
        )
        if (userTypeForm === 2) {
            drawerComponent = (
                <PMUserPhysicianForm content={drawerContent} closeFxn={() => {
                    setUserTypeForm(-1);
                    setShowDrawer(false);
                }}/>
            )
        } else if (userTypeForm === 3) {
            drawerComponent = (
                <PMUserPatientForm content={drawerContent} closeFxn={() => {
                    setUserTypeForm(-1);
                    setShowDrawer(false);
                }}/>
            )
        } else if (userTypeForm === 4) {
            drawerComponent = (
                <PMUserCaregiverForm content={drawerContent} closeFxn={() => {
                    setUserTypeForm(-1);
                    setShowDrawer(false);
                }}/>
            )
        }
    }
    
    return (
        <React.Fragment>
            {drawerOverlayComponent}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Users" breadcrumbItem="User Management 2.6"/>
                    {drawerComponent}
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <div className={"table-responsive"}>
                                    <DataTable/>
                                </div>
                            </Card>
                        </Col>
                    </Row>
    
                    <Modal isOpen={modal} toggle={()=> { setModal(!modal)}} >
                        <ModalHeader toggle={()=> { setModal(!modal)}} close={()=> { setModal(!modal)}}>Confirm Delete</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete the user:<br/><b>{`${userToDeleteDisplay}`}</b>?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={()=> { deleteHandler(userToDeleteID, userToDeleteType)}}>Confirm Delete</Button>{' '}
                            <Button color="secondary" onClick={()=> { setModal(!modal)}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
    
                    <Modal isOpen={enableModal} toggle={()=> { setEnableModal(!enableModal)}} >
                        <ModalHeader toggle={()=> { setEnableModal(!enableModal)}}>Confirm Delete</ModalHeader>
                        <ModalBody>
                            Are you sure you want to re-enable the user:<br/><b>{`${userToEnableDisplay}`}</b>?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={()=> { enableHandler(userToEnableID, userToEnableType)}}>Confirm Enable</Button>{' '}
                            <Button color="secondary" onClick={()=> { setEnableModal(!enableModal)}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PMUsersArchiveDataTable;
