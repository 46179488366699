import BootstrapTheme                   from '@fullcalendar/bootstrap';
import dayGridPlugin                    from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import FullCalendar                   from '@fullcalendar/react';
import React, { useEffect, useState } from 'react';
import BarLoader                      from "react-spinners/BarLoader";

import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useMutation, useQuery }                                                                                                            from "urql";

//Import Breadcrumb
import Breadcrumbs        from '../../components/Common/Breadcrumb';
import { PMTaskForm }     from "../Tables/PMTaskForm";
import { PMTaskViewForm } from "../Tables/PMTaskViewForm";

const DefaultEvents = [
    {
        id: 1,
        title: 'Error! Events Not Loading!',
        start: new Date().setDate(new Date().getDate() + 1),
        end: new Date().setDate(new Date().getDate() + 2),
        className: 'bg-warning text-white'
    }
];

const DefaultCategories = [
    {
        id: 1,
        title: 'Completed',
        type: 'success',
        colour: "alert-light-green"
    },
    {
        id: 2,
        title: 'Unseen',
        type: 'info',
        colour: "alert-light-teal"
    },
    {
        id: 3,
        title: 'Unable to Complete',
        type: 'warning',
        colour: "alert-light-orange"
    },
    {
        id: 4,
        title: 'Did Not Do',
        type: 'danger',
        colour: "alert-light-red"
    },
    {
        id: 4,
        title: 'Will Do Later',
        type: 'purple',
        colour: "alert-light-purple"
    },
];
const event1 = {id: 0, title: "", title_category: "", start: "", end: "", className: "", category: "", event_category: "", metadata: {caregiverAlias:""}};

const QUERY_GET_ALL_PATIENTS = `
query {
    getAllPatients {
        id,
        alias,
        names {
            firstName,
            lastName
        }
    }
}
`;

const GET_MONTHLY_TASKS = `
    query getSpecificationInteractionTaskPatientAssociationsForThisMonthByPatientId($patientID: String!, $timezoneOffset: Float) {
        getSpecificationInteractionTaskPatientAssociationsForThisMonthByPatientId(patientID: $patientID, timezoneOffset: $timezoneOffset) {
            taskID
            taskDisplay
            taskValidFrom
            taskExpiresAt
            patientAlias
            specificationID
            specificationDisplay
            caregiverUpdated
            caregiverName
            caregiverRelationship
            measureID
            measureDisplay
            measureValue
            countResponses
        }
    }
`;

const M_UPDATE_TASK = `
mutation updateSpecificationInteractionTask($id: String!, $validFrom: DateTime!, $expiresAt: DateTime!) {
  updateSpecificationInteractionTask(id: $id, validFrom: $validFrom, expiresAt: $expiresAt)
}
`;

export const Colors = {
    light: {
        text: "#000",
        textAlt: "#111",
        background: "#f7f8fc",
        tabIconDefault: "#eee",
        blue: "rgba(0,122,255,1.0)",
        green: "rgba(52,199,89,1.0)",
        indigo: "rgba(88,86,214,1.0)",
        orange: "rgb(255,185,0)",
        pink: "rgba(255,45,85,1.0)",
        purple: "rgba(175,82,222,1.0)",
        red: "rgba(255,59,48,1.0)",
        teal: "rgba(90,200,250,1.0)",
        yellow: "rgba(255,204,0,1.0)",
        gray: "rgba(142,142,147,1.0)",
        gray2: "rgba(174,174,179,1.0)",
        gray3: "rgba(199,199,204,1.0)",
        gray4: "rgba(209,209,214,1.0)",
        white: "rgba(255,255,255,1.0)",
    }
};

export const getTaskColour = (measureValue) => {
    
    let colour = 'light-teal';
    
    if (measureValue !== undefined) {
        switch (measureValue) {
            case 0:
                colour = 'light-blue';
                break;
            case 1:
                colour = 'light-teal';
                break;
            case 2:
                colour = 'light-orange';
                break;
            case 3:
                colour = 'light-green';
                break;
            case 4:
                colour = 'light-red';
                break;
            case 5:
                colour = 'light-gray';
                break;
            case 6:
                colour = 'light-purple';
                break;
        }
    }
    return colour;
}


const Index = (props) => {
    
    const [calendarEvents, setCalendarEvents] = useState(DefaultEvents);
    const [categories, setCategories] = useState(DefaultCategories);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modalcategory, setModalcategory] = useState(false);
    const [event, setEvent] = useState(event1);
    const [selectedDay, setSelectedDay] = useState(0);
    const title_category = false;
    
    const [selectedPatient, setSelectedPatient] = useState('00000000-0000-0000-0000-000000000000');
    const [selectedPatientAlias, setSelectedPatientAlias] = useState('');
    const [selectedCaregiver, setSelectedCaregiver] = useState('');
    const [userSelectOpen, setUserSelectOpen] = useState(false);
    const toggleUserSelect = () => setUserSelectOpen(prevState => !prevState);
    
    
    const [registerUpdateTaskResult, updateTask] = useMutation(M_UPDATE_TASK);
    
    const calendarComponentRef = React.createRef();
    
    useEffect(() => {
        new Draggable(document.getElementById("external-events"), {
            itemSelector: '.external-event',
        });
    });
    
    /**
     * Handling the modal state
     */
    function toggle() {
        setModal(!modal)
    }
    
    function toggle1() {
        setModal1(!modal1)
    }
    
    function togglecategory() {
        setModalcategory(!modalcategory)
    }
    
    /**
     * Handling date click on calendar
     */
    const handleDateClick = (arg) => {
        setSelectedDay(arg);
        toggle();
    }
    
    /**
     * Handling click on event on calendar
     */
    const handleEventClick = (arg) => {
        const eventNew = arg.event;
        
        console.log("eventData", arg);
        
        const event_tmp = {
            id: eventNew.id,
            title: eventNew.title,
            title_category: eventNew.title_category,
            start: eventNew.start,
            end: eventNew.end,
            className: eventNew.classNames,
            category: eventNew.classNames[0],
            event_category: eventNew.classNames[0]
        };
        
        setEvent(event_tmp);
        toggle1();
    }
    
    /**
     * On calendar drop event
     */
    const onDrop = (info) => {
        let variables = {
            id: info.event.id,
            validFrom: info.event.start.toISOString(),
            expiresAt: info.event.end.toISOString()
        };
        
        updateTask(variables).then(result => {
            console.log(result);
        });
    }
    
    const UserSelector = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_ALL_PATIENTS,
            requestPolicy: "network-only"
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        if (fetching) {
            return (
                <>
                    <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
                </>
            );
        } else if (error) {
            return (
                <>
                    <p>Oh no... {error.message}</p>
                </>
            );
        } else {
            return (
                <>
                    <Dropdown isOpen={userSelectOpen} toggle={toggleUserSelect}>
                        <DropdownToggle caret className="btn-block">
                            {selectedPatientAlias !== '' ? selectedPatientAlias : 'Select Patient...'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {data.getAllPatients.map((item, index) => (
                                <DropdownItem key={index} onClick={() => {
                                    setSelectedPatient(item.id);
                                    setSelectedPatientAlias(item.alias);
                                }}>{item.names.lastName}, {item.names.firstName} ({item.alias})</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </>
            );
        }
    };
    
    const UserCalendar = () => {
        const [result, refreshQuery] = useQuery({
            query: GET_MONTHLY_TASKS,
            variables: {
                patientID: selectedPatient,
                timezoneOffset: 0,
                physicianOverride: true
            },
            requestPolicy: "network-only"
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        if (fetching) {
            return (
                <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
            );
        } else if (error) {
            return (
                <>
                    <p>{error.message} - Did you select a patient on the left?</p>
                </>
            );
        } else {
            let events = [];
            if (data) {
                data.getSpecificationInteractionTaskPatientAssociationsForThisMonthByPatientId.map((item, index) => {
                    let colorName = getTaskColour(item.measureValue);
                    let cn = item.caregiverName || '';
                    let cr = item.caregiverRelationship || '';
                    let t = item.taskDisplay;
                    if(cn !== '' && cr !== ''){
                        t = t + " (" + cn + ": " + cr + ")";
                    } else {
                        if(cn === '' && cr === ''){
                            t = t;
                        } else {
                            if(cn!==''){
                                t = t + " (" + cn + ")";
                            } else {
                                t = t + " (" + cr + ")";
                            }
                        }
                    }
                    events.push({
                        id: item.taskID,
                        title: t,
                        start: new Date(item.taskValidFrom),
                        end: new Date(item.taskExpiresAt),
                        className: `bg-${colorName} text-white`,
                        metadata: {
                            caregiverName: cn,
                            caregiverRelationship: cr
                        }
                    });
                })
            }
            
            
            return (
                <FullCalendar ref={calendarComponentRef} defaultView="dayGridMonth"
                              plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                              slotDuration={'00:15:00'}
                              minTime={'08:00:00'}
                              maxTime={'19:00:00'}
                              handleWindowResize={true}
                              themeSystem="bootstrap"
                              header={{
                                  left: 'prev,next today',
                                  center: 'title',
                                  right: 'dayGridMonth,dayGridWeek,dayGridDay'
                              }}
                              events={events}
                              editable={true}
                              droppable={true}
                              eventLimit={true}
                              selectable={true}
                              dateClick={handleDateClick}
                              eventClick={handleEventClick}
                              eventDrop={onDrop}
                              id="calendar"/>
            );
        }
    };
    
    console.log('selectedPatientAlias', selectedPatientAlias);
    console.log('selectedPatient', selectedPatient);
    
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="User Tasks" breadcrumbItem="Task Management"/>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            
                                            <div id="user-select" className="mt-3" role="region">
                                                <UserSelector/>
                                            </div>
                                            
                                            <div className="mt-5 d-none d-xl-block">
                                            </div>
                                            
                                            <Button color="primary" className="font-16 btn-block" onClick={() => toggle()} role="region">
                                                <i className="mdi mdi-plus-circle-outline"></i> Create New Task
                                            </Button>
                                            
                                            <div id="external-events" className="mt-3">
                                                <p className="text-muted">Drag and drop your event or click in the calendar</p>
                                                
                                                {
                                                    categories.map((category, i) => {
                                                        let classname = `alert ${category.colour} fade show`;
                                                        return <div className={classname} role="alert" key={i}>{category.title}</div>
                                                    })
                                                }
                                            </div>
                                            
                                            <div className="mt-5 d-none d-xl-block">
                                            </div>
                                        </Col>
                                        <Col className="col-lg-9">
                                            
                                            <UserCalendar/>
                                            
                                            {/* New event modal */}
                                            <Modal isOpen={modal} toggle={() => toggle()} className="" role="dialog" aria-label="Create Task Dialog">
                                                <ModalHeader toggle={() => toggle()} tag="h4">
                                                    Add Task
                                                </ModalHeader>
                                                <ModalBody>
                                                    <PMTaskForm closeFn={toggle} selectedDate={selectedDay} selectedPatient={selectedPatient} selectedPatientAlias={selectedPatientAlias}/>
                                                </ModalBody>
                                            </Modal>
                                            
                                            {/* edit event modal */}
                                            <Modal isOpen={modal1} toggle={() => toggle1()} className="" role="dialog" aria-label="View Task Dialog">
                                                <ModalHeader toggle={() => toggle1()} tag="h4">
                                                    View Event
                                                </ModalHeader>
                                                <ModalBody>
                                                    <PMTaskViewForm closeFn={toggle} selectedPatientAlias={selectedPatientAlias} event={event}/>
                                                </ModalBody>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Index;
