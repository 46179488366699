import React                           from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useAuth } from '@frontegg/react'

const Authmiddleware = ({component: Component, layout: Layout}) => {
    
    const { user, isAuthenticated } = useAuth();
    const isThisUserAuthenticated = isAuthenticated || localStorage.getItem("authUser") || false;
    
    return (
    
        <Route
            render={props => {
            
                // here you can apply condition
                // if (!localStorage.getItem("authUser")) {
                // if (!isAuthenticated) {
                //     return (
                //         <Redirect to={{pathname: "/sign-up", state: {from: props.location}}}/>
                //     );
                // }
    
                if (isThisUserAuthenticated) {
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    );
                } else {
    
                    return (
                            <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                        );
                }
                
            }}
        />
    );
}

export default withRouter(Authmiddleware);

