import React, { useEffect, useState }                                                              from 'react';
import BarLoader                                                                                   from "react-spinners/BarLoader";
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";
import { useMutation, useQuery }                                                                   from "urql";
import voca                                                                                        from 'voca';

// Custom Modules
import Breadcrumbs                from '../../components/Common/Breadcrumb';
import { FORM_CONTEXT_SPEC_EDIT } from "../../components/Common/Constants";
import { CustomDataTable }        from "./CustomDataTable";
import "./datatables.scss";
import { PMPromptEditForm }       from "./PMPromptEditForm";

const QUERY_GET_ALL_PROMPTS = `
query GetAllSpecificationInteractions($enabled: Boolean!) {
    getAllSpecificationInteractions(enabled: $enabled) {
        id
        coding {
            key
            display
        }
        enabled
        specification {
            coding {
                key
                display
            }
            type
        }
    }
}
`;

const M_DISABLE_SPEC_INTERAC = `
mutation  disableSpecificationInteraction($i: String!) {
  disableSpecificationInteraction(id: $i)}
`;

const mapQueryResult = (data) => {
    let tableData = [];
    data.getAllSpecificationInteractions.map(spec => (
        tableData.push({
            id: spec.id,
            key: spec.coding.key,
            display: spec.coding.display,
            type: "prompt",
            typeSpec: spec.specification.coding.display + " (" + spec.specification.coding.key + ")",
            usages: "unknown"
        })
    ));
    return tableData;
}

const POLLING_INTERVAL = 5 * 60 * 100 * 10;// remove the * 10 when you fix the filter issue

const PMPromptsDataTable = (props) => {
    
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const [modal, setModal] = useState(false);
    const [specificationToDeleteDisplay, setSpecificationToDeleteDisplay] = useState('');
    const [specificationToDeleteType, setSpecificationToDeleteType] = useState('');
    const [specificationToDeleteID, setSpecificationToDeleteID] = useState('');
    const [deleteSpecResult, deleteSpec] = useMutation(M_DISABLE_SPEC_INTERAC);
    
    // this is a last ditch attempt to re-hydrate from the url location in the event the end user has browser plugins
    // like The Great Suspender that might suspend the tab and then re-hydrate the tab without state information
    const specificationType = props.location.state ? props.location.state.type : props.location.pathname.slice(1, props.location.pathname.length - 1);
    const breadcrumbTitle = voca.titleCase(props.location.pathname.slice(1, props.location.pathname.length));
    
    useEffect(() => {
        setShowDrawer(showDrawer);
    }, [showDrawer])
    
    let tableData = [];
    
    //TODO might need to be cache-and-network
    const DataTable = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_ALL_PROMPTS,
            requestPolicy: "cache-and-network",
            pollInterval: POLLING_INTERVAL,
            variables: {
                enabled: true
            }
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        
        let infoComponent;
        if (fetching) {
            infoComponent = <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            infoComponent = <p>Oh no... {error.message}</p>;
        } else {
            infoComponent = <></>;
            tableData = mapQueryResult(data);
        }
        
        return (
            <>
                {infoComponent}
                <CustomDataTable columns={columns} data={tableData}/>
            </>
        );
        
    };
    
    const editRowHandler = (data, shouldShow, context = FORM_CONTEXT_SPEC_EDIT) => {
        data.context = context;
        if (shouldShow) {
            setDrawerContent(JSON.stringify(data))
        } else {
            setDrawerContent('JSON.stringify(data)')
        }
        setShowDrawer(shouldShow);
        
    };
    
    const deleteRowHandler = (data, shouldShow, context = 'delete') => {
        data.context = context;
        if (shouldShow) {
            setSpecificationToDeleteID(data.id);
            setSpecificationToDeleteType(data.type);
            setSpecificationToDeleteDisplay(data.display);
        } else {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
        }
        setModal(shouldShow);
    };
    
    const deleteHandler = (id) => {
        deleteSpec({i: id}).then(() => {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
            setModal(false);
        })
        
    };
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Coding',
                columns: [
                    {
                        Header: 'Key',
                        accessor: 'key'
                    },
                    {
                        Header: 'Display',
                        accessor: 'display'
                    },
                    {
                        Header: 'Associated Skill',
                        accessor: 'typeSpec'
                    }
                ],
            },
            {
                Header: 'Usage Stats',
                columns: [
                    {
                        Header: 'Tasks',
                        accessor: 'usages',
                    }
                ]
            },
            {
                Header: 'Actions',
                columns: [
                    {
                        Header: '',
                        accessor: 'actions',
                        Cell: ({cell: {value, ...props}}) => {
                            return (
                                <React.Fragment>
                                    <Row>
                                        <Col className="col-6 col-sm">
                                            <button className="btn btn-primary btn-sm waves-effect waves-light mr-1" onClick={() => {
                                                editRowHandler(props.row.original, true);
                                            }}>
                                                <i className="mdi mdi-account-edit-outline mdi-18px"/>
                                                <span style={{textDecoration: "none", color: "white"}} href="#" id="EditToolTip"> Edit</span>
                                                <UncontrolledTooltip placement="bottom" target="EditToolTip">
                                                    Click here to Edit this Prompt
                                                </UncontrolledTooltip>
                                            </button>
                                        </Col>
                                        <Col className="col-6 col-sm">
                                            <button className="btn btn-danger btn-sm waves-effect waves-light" onClick={() => {
                                                deleteRowHandler(props.row.original, true);
                                            }}>
                                                <i className="mdi mdi-trash-can-outline mdi-18px"/>
                                                <span style={{textDecoration: "none", color: "white"}} href="#" id="DeleteToolTip"> Disable</span>
                                                <UncontrolledTooltip placement="bottom" target="DeleteToolTip">
                                                    Click here to Disable this Prompt
                                                </UncontrolledTooltip>
                                            </button>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                        },
                        Filter: () => {
                            return null;
                        }
                    }
                ],
                minWidth: 175,
            }
        ],
        []
    );
    
    let drawerComponent = '';
    let drawerOverlayComponent = '';
    if (showDrawer) {
        drawerComponent = (
            <PMPromptEditForm content={drawerContent} closeFxn={() => {
                setShowDrawer(false);
            }}/>
        )
        drawerOverlayComponent = (
            <div className="drawer-overlay"></div>
        )
    }
    
    return (
        <React.Fragment>
            {drawerOverlayComponent}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem="Prompt Management"/>
                    {drawerComponent}
                    <Row>
                        <Col className="col-12 ">
                            <Card>
                                {/*<CardBody>*/}
                                <div className={"table-responsive"}>
                                    <DataTable/>
                                </div>
                                {/*</CardBody>*/}
                            </Card>
                        </Col>
                    </Row>
                    
                    
                    <Modal isOpen={modal} toggle={() => {
                        setModal(!modal)
                    }}>
                        <ModalHeader toggle={() => {
                            setModal(!modal)
                        }} close={() => {
                            setModal(!modal)
                        }}>Confirm Delete</ModalHeader>
                        <ModalBody>
                            Are you sure you want to disable the prompt:<br/><b>{`${specificationToDeleteDisplay}`}</b>?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => {
                                deleteHandler(specificationToDeleteID)
                            }}>Confirm Disable</Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setModal(!modal)
                            }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                
                </div>
            </div>
        </React.Fragment>
    );
}

export default PMPromptsDataTable;
