import React from 'react';

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link }                     from "react-router-dom";

import avatar1    from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

const WelcomeComp = (props) => {
    return (
        <React.Fragment>
            <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                    <Row>
                        <Col xs="7">
                            <div className="text-primary p-3">
                                <h5 className="text-primary">Welcome Back !</h5>
                                <p>Admin Dashboard</p>
                            </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                            <img src={profileImg} alt="" className="img-fluid"/>
                        </Col>
                    </Row>
                </div>
                <CardBody className="pt-0">
                    <Row>
                        <Col sm="4">
                            <div className="avatar-md profile-user-wid mb-4">
                                <img src={avatar1} alt="" className="img-thumbnail rounded-circle"/>
                            </div>
                            <h5 className="font-size-15 text-truncate">Jon Perle</h5>
                            <p className="text-muted mb-0 text-truncate">Physician</p>
                        </Col>
                        
                        <Col sm="8">
                            <div className="pt-4">
                                <Row>
                                    <Col xs="6">
                                        <h5 className="font-size-15">125</h5>
                                        <p className="text-muted mb-0">Tasks Today</p>
                                    </Col>
                                    <Col xs="6">
                                        <h5 className="font-size-15">245</h5>
                                        <p className="text-muted mb-0">Logs Today</p>
                                    </Col>
                                </Row>
    
                                <Row>
                                    <Col xs="6">
                                        <div className="mt-4">
                                            {/*<Link to="" className="btn btn-primary waves-effect waves-light btn-sm"><a href={"https://pmt.ngrok.io/data"}> Tasks Report</a> <i*/}
                                            {/*    className="mdi mdi-arrow-right ml-1"></i></Link>*/}
                                            
                                            <a href={"https://pmt.ngrok.io/data"}> Tasks Report</a>
                                        </div>
                                    </Col>
                                    <Col xs="6">
                                        <div className="mt-4">
                                            <Link to="https://pmt.ngrok.io/data/logs" className="btn btn-primary waves-effect waves-light btn-sm">Logs Report <i
                                                className="mdi mdi-arrow-right ml-1"></i></Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}
export default WelcomeComp;
