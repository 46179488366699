import React, { useEffect, useState }                                                              from 'react';
import BarLoader                                                                                   from "react-spinners/BarLoader";
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";
import { useMutation, useQuery }                                                                   from "urql";
import voca                                                                                        from 'voca';

// Custom Modules
import Breadcrumbs                             from '../../components/Common/Breadcrumb';
import { FORM_CONTEXT_SPEC_EDIT }              from "../../components/Common/Constants";
import { CustomDataTable }                     from "./CustomDataTable";
import "./datatables.scss";
import { M_DELETE_SPEC, M_ENABLE_SPEC, PMSpecificationsForm } from "./PMSpecificationsForm";

const QUERY_GET_DISABLED_SPECIFICATIONS = `
query GetAllDisabledSpecifications {
    getAllDisabledSpecifications {
        id
        coding {
            key
            display
            description
        }
        sorting {
            weight
        }
        type
    }
}
`;

const mapQueryResult = (data) => {
    let tableData = [];
    data.getAllDisabledSpecifications.map(spec => (
        tableData.push({
            id: spec.id,
            key: spec.coding.key,
            display: spec.coding.display,
            description: spec.coding.description,
            weight: spec.sorting.weight,
            type: spec.type,
            associations: 0,
            actions: ''
        })
    ));
    return tableData;
}

const POLLING_INTERVAL = 5 * 60 * 100 * 10;// remove the * 10 when you fix the filter issue

const PMSpecificationsDataTable = (props) => {
    
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const [modal, setModal] = useState(false);
    const [specificationToDeleteDisplay, setSpecificationToDeleteDisplay] = useState('');
    const [specificationToDeleteType, setSpecificationToDeleteType] = useState('');
    const [specificationToDeleteID, setSpecificationToDeleteID] = useState('');
    const [deleteSpecResult, deleteSpec] = useMutation(M_DELETE_SPEC);
    const [enableSpecResult, enableSpec] = useMutation(M_ENABLE_SPEC);
    
    // this is a last ditch attempt to re-hydrate from the url location in the event the end user has browser plugins
    // like The Great Suspender that might suspend the tab and then re-hydrate the tab without state information
    // const specificationType = props.location.state ? props.location.state.type : props.location.pathname.slice(1, props.location.pathname.length - 1);
    const breadcrumbTitle = voca.titleCase(props.location.pathname.slice(1, props.location.pathname.length));
    const breadcrumbTitleLong = breadcrumbTitle + " Management"
    
    useEffect(() => {
        setShowDrawer(showDrawer);
    }, [showDrawer])
    
    let tableData = [];
    
    const DataTable = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_DISABLED_SPECIFICATIONS,
            requestPolicy: "cache-and-network",
            pollInterval: POLLING_INTERVAL
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        
        let infoComponent;
        if (fetching) {
            infoComponent = <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            infoComponent = <p>Oh no... {error.message}</p>;
        } else {
            infoComponent = <></>;
            tableData = mapQueryResult(data);
        }
        
        return (
            <>
                {infoComponent}
                <CustomDataTable columns={columns} data={tableData}/>
            </>
        );
        
    };
    
    const deleteRowHandler = (data, shouldShow, context = 'delete') => {
        data.context = context;
        if (shouldShow) {
            setSpecificationToDeleteID(data.id);
            setSpecificationToDeleteType(data.type);
            setSpecificationToDeleteDisplay(data.display);
        } else {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
        }
        setModal(shouldShow);
    };
    
    const deleteHandler = (id) => {
        deleteSpec({i: id}).then(() => {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
            setModal(false);
        })
    };
    
    const enableRowHandler = (id) => {
        enableSpec({i: id}).then(() => {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
            setModal(false);
        })
    };
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Coding',
                columns: [
                    {
                        Header: 'Weight',
                        accessor: 'weight'
                    },
                    {
                        Header: 'Key',
                        accessor: 'key'
                    },
                    {
                        Header: 'Display',
                        accessor: 'display'
                    },
                    {
                        Header: 'Description',
                        accessor: 'description'
                    }
                ],
            },
            {
                Header: 'Usage Stats',
                columns: [
                    {
                        Header: 'Associations',
                        accessor: 'associations',
                    }
                ]
            },
            {
                Header: 'Actions',
                columns: [
                    {
                        Header: '',
                        accessor: 'actions',
                        Cell: ({cell: {value, ...props}}) => {
                            return (
                                <React.Fragment>
                                    <Row>
                                        <Col className="col-6 col-sm">
                                            <button className="btn btn-success btn-sm waves-effect waves-light" onClick={() => {
                                                enableRowHandler(props.row.original.id);
                                            }}><i className="mdi mdi-account-edit mdi-18px"/> <span style={{textDecoration: "none", color: "white"}} href="#" id="EnableSABC"> Enable</span>
                                                <UncontrolledTooltip placement="bottom" target="EnableSABC">
                                                    Click here to Re-enable this A/B/C
                                                </UncontrolledTooltip>
                                            </button>
                                        </Col>
                                        <Col className="col-6 col-sm">
                                            <button className="btn btn-danger btn-sm waves-effect waves-light" onClick={() => {deleteRowHandler(props.row.original, true);}}>
                                                <i className="mdi mdi-trash-can-outline mdi-18px"/>
                                                <span style={{textDecoration: "none", color: "white"}} href="#" id="DeleteSpec"> Permanently Delete</span>
                                                <UncontrolledTooltip placement="bottom" target="DeleteSpec">
                                                    Permanently Delete  S/A/B/C
                                                </UncontrolledTooltip>
                                            </button>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                        },
                        Filter: () => {
                            return null;
                        }
                    }
                ],
                minWidth: 175,
            }
        ],
        []
    );
    
    let drawerComponent = '';
    let drawerOverlayComponent = '';
    if (showDrawer) {
        drawerComponent = (
            <PMSpecificationsForm content={drawerContent} closeFxn={() => {
                setShowDrawer(false);
            }}/>
        )
        drawerOverlayComponent = (
            <div className="drawer-overlay"></div>
        )
    }
    
    return (
        <React.Fragment>
            {drawerOverlayComponent}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbTitleLong}/>
                    {drawerComponent}
                    <Row>
                        <Col className="col-12 ">
                            <Card>
                                {/*<CardBody>*/}
                                <div className={"table-responsive"}>
                                    <DataTable/>
                                </div>
                                {/*</CardBody>*/}
                            </Card>
                        </Col>
                    </Row>
                    
                    
                    <Modal isOpen={modal} toggle={() => {
                        setModal(!modal)
                    }}>
                        <ModalHeader toggle={() => {setModal(!modal)}}>Confirm Delete</ModalHeader>
                        <ModalBody>
                            Are you sure you want to permanently delete the {`${specificationToDeleteType}`}:<br/><b>{`${specificationToDeleteDisplay}`}</b>?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => {
                                deleteHandler(specificationToDeleteID)
                            }}>Confirm Delete</Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setModal(!modal)
                            }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                
                </div>
            </div>
        </React.Fragment>
    );
}

export default PMSpecificationsDataTable;
