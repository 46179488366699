import React, { useState }               from "react";
import BarLoader                         from "react-spinners/BarLoader";
import { Card, Container, Input, Table } from "reactstrap";
import { useMutation, useQuery }         from "urql";

const Q_GET_ALL_PATIENT_SPECIFICATIONS = `
query getAllPatientSpecificationAssociations {
    getAllPatientSpecificationAssociations {
        id
        specification {
            id
            coding {
                key
                display
            }
            type
        }
        patient {
            id
            alias
        }
        enabled
    }
}
`;

const M_REGISTER_SPEC = `
mutation registerSpecification($t: String!, $ck: String!, $cd: String!, $e: Boolean!, $d: String!, $w: Float!) {
  registerSpecification(type: $t, codingKey: $ck, codingDisplay: $cd, enabled: $e, description: $d, weight: $w)}
`;

const M_UPDATE_SPEC = `
mutation updateSpecification($i: String!, $ck: String!, $cd: String!, $e: Boolean!, $d: String!, $w: Float!) {
  updateSpecification(id: $i, codingKey: $ck, codingDisplay: $cd, enabled: $e, description: $d, weight: $w)}
`;

export const M_DELETE_SPEC = `
mutation  deleteSpecification($i: String!) {
  deleteSpecification(id: $i)}
`;

export const PMAssociationBuilderSkillUser = (props) => {
    
    const [content, setContent] = useState(JSON.parse(props.content));
    const [updateSpecResult, updateSpec] = useMutation(M_UPDATE_SPEC);
    const [registerSpecResult, registerSpec] = useMutation(M_REGISTER_SPEC);
    
    const onSubmit = (navigateAway = false) => {
        
        registerSpec().then(result => {
            console.log(result);
            if (result.data.registerSpecification === true) {
            
            }
        })
        
        updateSpec().then(result => {
            if (result.data.updateSpecification === true) {
                alert("saved!")
            }
        });
    };
    
    const clickHandler = (specification, patient, isEnabled) => {
        console.log(specification, patient, isEnabled);
    }
    
    
    const AssociationMatrix = () => {
        
        const [result] = useQuery({
            query: Q_GET_ALL_PATIENT_SPECIFICATIONS,
            requestPolicy: "network-only"
        });
        
        const {data, fetching, error} = result;
        
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Oh no... {error.message}</p>;
        } else {
            let headerRow = [];
            let rows = [];
            
            let currentSpecification = '';
            let currentRow = [];
            let firstPass = true;
            for (let i = 0; i < data.getAllPatientSpecificationAssociations.length; i++) {
                let d = data.getAllPatientSpecificationAssociations[i];
                if (d.specification.id !== currentSpecification) {
                    if (firstPass) {
                        currentRow.push(' ');
                    }
                    if (!firstPass) {
                        rows.push(currentRow);
                        currentRow = [];
                        currentRow.push(d.specification.coding.key);
                    }
                    firstPass = false;
                    currentSpecification = d.specification.id;
                }
                currentRow.push({enabled: d.enabled, s: d.specification.id, p: d.patient.id});
                if (!headerRow.includes(d.patient.alias)) {
                    headerRow.push(d.patient.alias);
                }
            }
            
            console.log(JSON.stringify([headerRow, rows]));
            
            let tableHead = [];
            tableHead.push(<th>&nbsp;</th>);
            for (let i = 0; i < headerRow.length; i++) {
                tableHead.push(<th>{headerRow[i]}</th>);
            }
            
            let tableBody = [];
            
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                let unwrappedRow = [];
                for (let j = 0; j < row.length; j++) {
                    if (j === 0) {
                        console.log(row[j]);
                        unwrappedRow.push(<th scope="row">{row[j]}</th>);
                    } else {
                        let random_boolean = Math.random() < 0.5
                        if (random_boolean) {
                            // if(row[j].enabled){
                            unwrappedRow.push(
                                <td>
                                    <Input type="checkbox" onClick={() => {
                                        clickHandler(row[j].s, row[j].p, !row[j].enabled)
                                    }} checked/>
                                </td>
                            );
                        } else {
                            unwrappedRow.push(
                                <td>
                                    <Input type="checkbox" onClick={() => {
                                        clickHandler(row[j].s, row[j].p, !row[j].enabled)
                                    }}/>
                                </td>
                            );
                        }
                        
                        // unwrappedRow.push(<td>{row[j]}</td>);
                    }
                }
                tableBody.push(<tr>{unwrappedRow}</tr>)
            }
            
            
            return (
                <Table borderless>
                    <thead>
                    <tr>
                        {tableHead}
                    </tr>
                    </thead>
                    <tbody>{tableBody}</tbody>
                </Table>
            )
                
                ;
        }
    };
    
    
    return (
        <Card>
            <Container fluid={true}>
                <AssociationMatrix/>
            </Container>
        </Card>
    );
};
