import "flatpickr/dist/themes/material_green.css";
import _                                from 'lodash';
import React, { useCallback, useState } from 'react';

import Flatpickr                                                from "react-flatpickr";
import { useForm }                                              from "react-hook-form";
import BarLoader                                                from 'react-spinners/BarLoader';
import { Button, ButtonGroup, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { useMutation, useQuery }                                from 'urql';
import { CaregiverSelector }                                    from "../../modules/common-ui/CaregiverSelector";
import { PatientSelector }                                      from "../../modules/common-ui/PatientSelector";


const M_REGISTER_TASK = `
mutation registerTask($specificationInteractionID: String!, $patientID: String!, $validFrom: DateTime!, $expiresAt: DateTime!, $caregivers: String!) {
  registerTask(specificationInteractionID: $specificationInteractionID, patientID: $patientID, validFrom: $validFrom, expiresAt: $expiresAt, caregivers: $caregivers)
}
`;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const useDebounce = (obj = null, wait = 2500) => {
    const [state, setState] = useState(obj);
    
    const debounce = useCallback(
        _.debounce((_prop) => {
            setState(_prop);
        }, wait),
        []
    );
    
    const setDebouncedState = (_val) => {
        debounce(_val);
    };
    
    return [state, setDebouncedState];
};

export const PMTaskForm = (props) => {
    
    console.log("props", props);
    
    let vf = new Date();
    console.log("vf1", vf);
    let vfe = new Date(vf.getTime() + 15 * 60000); // plus 15 mins
    let d = new Date();
    if (props.selectedDate && props.selectedDate.dateStr) {
        let dateClickedOnCalendar = props.selectedDate.date;
        let dateForHours = new Date();
        vf = dateClickedOnCalendar;
        vf.setHours(dateForHours.getHours());
        vf.setMinutes(dateForHours.getMinutes())
        vfe = new Date(vf.getTime() + 15 * 60000);
        d = new Date(vf.toISOString());
    }
    d.setHours(23, 59, 0, 0);
    console.log("vf2", vf);
    
    const [formData, setFormData] = useState({
        specificationInteractionID: "",
        patientID: props.selectedPatient ? props.selectedPatient : '00000000-0000-0000-0000-000000000000',
        validFrom: vf,
        validFromEnd: vfe,
        expiresAt: d,
        cSelected: []
    })
    
    const {register, getValues, handleSubmit, formState: {errors}, reset, setValue, watch} = useForm({
        defaultValues: formData
    });
    
    const [registerTaskResult, registerTask] = useMutation(M_REGISTER_TASK);
    
    const [cSelected, setCSelected] = useState([]);
    const [randomTime, setRandomTime] = useState(new Date());
    const [useRandomTime, setUseRandomTime] = useState(false);
    
    const [selectedPatient, setSelectedPatient] = useState('00000000-0000-0000-0000-000000000000');
    
    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            console.log(value, name, type);
            if (name === 'patientID') {
                let d = JSON.parse(value.patientID);
                setSelectedPatient(d.id);
            }
            
            if (name === 'randomTime') {
                if (value.randomTime === true) {
                    let d = getRandomDateTime(value.validFrom, value.validFromEnd, value.validFrom, value.validFromEnd);
                    setRandomTime(d);
                    setUseRandomTime(true);
                    // console.log("will use random time in submission");
                } else {
                    setUseRandomTime(false);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    
    const onCheckboxBtnClick = (selected) => {
        const index = cSelected.indexOf(selected);
        if (index < 0) {
            cSelected.push(selected);
        } else {
            cSelected.splice(index, 1);
        }
        setCSelected([...cSelected]);
    }
    
    
    const onSubmit = (data) => {
        if (data.validFrom[0] === undefined) {
            data.validFrom[0] = vf;
        }
        
        if (data.validFromEnd[0] === undefined) {
            data.validFromEnd[0] = vfe;
        }
        
        if (data.expiresAt[0] === undefined) {
            data.expiresAt[0] = d;
        }
        
        let useThisValidFrom = data.validFrom[0].toUTCString();
        if(useRandomTime){
            useThisValidFrom = randomTime.toUTCString();
        }
        
        alert(useThisValidFrom);
        
        let variables = {
            specificationInteractionID: (JSON.parse(data.specificationInteractionID)).id,
            patientID: (JSON.parse(data.patientID)).id,
            validFrom: useThisValidFrom,
            expiresAt: data.expiresAt[0].toUTCString()
        };
        
        if (data.caregivers && data.caregivers.length > 0) {
            variables = {...variables, caregivers: JSON.stringify(data.caregivers)};
        } else {
            variables = {...variables, caregivers: JSON.stringify([])};
        }
        
        // if (cSelected.includes(5)) {
        //     let d = getRandomDateTime(data.validFrom[0], data.validFromEnd[0], data.validFrom[0], data.validFromEnd[0]);
        //     variables.validFrom = d;
        //     data.validFrom[0] = d;
        // }
        
        console.log("variables", variables);
        
        registerTask(variables).then(result => {
            console.log(result);
            reset(formData);
            props.closeFn();
        });
    };
    
    const validate = () => {
        console.log(errors);
        handleSubmit(onSubmit)();
    }
    
    const QUERY_GET_PROMPTS = `
        query {
            getAllSpecificationInteractions {
                id
                coding {
                    key
                    display
                 
                }
            }
        }
        `;
    
    const PromptSelector = () => {
        const [result] = useQuery({
            query: QUERY_GET_PROMPTS,
            requestPolicy: "network-only"
        });
        
        const {data, fetching, error} = result;
        if (fetching) {
            return (
                <>
                    <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
                </>
            );
        } else if (error) {
            return (
                <>
                    <p>Oh no... {error.message}</p>
                </>
            );
        } else {
            return (
                <>
                    <select name="prompts" className={"form-control form-control-lg"} {...register("specificationInteractionID")} aria-label="Prompts">
                        {data.getAllSpecificationInteractions.map((item, index) => (
                            <option key={index} value={JSON.stringify({id: item.id, display: item.coding.display})}>{item.coding.display} ({item.coding.key})</option>
                        ))}
                    </select>
                </>
            );
        }
    };
    
    const QUERY_GET_PATIENTS = `
        query {
            getAllPatients {
                id
                alias
            }
        }
    `;
    
    
    const QUERY_GET_CAREGIVERS = `
        query ($patientID: String!) {
            getAllCaregiversOfPatient (patientID: $patientID) {
                id
                names {
                    firstName
                }
            }
        }
    `;
    
    
    const getRandomDateTime = (fromDate, toDate, fromTime, toTime) => {
        let fd = fromDate.getTime();
        let td = toDate.getTime();
        fromTime.setFullYear(1970, 0, 1); //reset the date
        toTime.setFullYear(1970, 0, 1); //because we only want a time here
        fromTime = fromTime.getTime();
        toTime = toTime.getTime();
        let randomDate = new Date(fd + Math.random() * (td - fd));
        let randomTime = new Date(fromTime + Math.random() * (toTime - fromTime));
        let today = new Date();
        randomDate.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
        randomDate.setHours(randomTime.getHours());
        randomDate.setMinutes(randomTime.getMinutes());
        randomDate.setSeconds(randomTime.getSeconds());
        return randomDate;
    }
    
    return (
        <Row>
            <Col>
                <Card className="shadow-none">
                    <CardBody>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Select Patient</Label>
                                    <PatientSelector query={QUERY_GET_PATIENTS} register={register}/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Select Prompt</Label>
                                    <PromptSelector/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Include the Following Caregivers</Label>
                                    <CaregiverSelector query={QUERY_GET_CAREGIVERS} variables={{patientID: selectedPatient}} register={register}/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-6">
                                <div className="mt-3">
                                    <Label>Time to Send (Start)</Label><br/>
                                    <Flatpickr data-enable-time value={formData.validFrom} options={{minuteIncrement: 1}} onChange={date => {
                                        setValue("validFrom", date)
                                    }} aria-label="Prompt Valid From"/>
                                </div>
                            </Col>
                            
                            <Col className="col-6">
                                <div className="mt-3">
                                    <Label>Time to Send (End)</Label><br/>
                                    <Flatpickr data-enable-time value={formData.validFromEnd} options={{minuteIncrement: 1}} onChange={date => {
                                        setValue("validFromEnd", date)
                                    }} aria-label="Prompt Valid From End"/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-12">
                                    <br/>
                                    <input type="checkbox" placeholder="randomTime" {...register("randomTime", {})} />
                                    <Label>&nbsp;&nbsp;Use a Random Time Between the Two Above Times</Label><br/>
                                    {/*<span>Random Time: {randomTime.getFullYear() + "-"+randomTime.getMonth() + "-"+randomTime.getDate()  + " "+randomTime.getHours() +":"+randomTime.getMinutes()}</span>*/}
                                    <span>Random Time: {randomTime.toLocaleString('fr-CA', { hour12: false })}</span>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-6">
                                <div className="mt-3">
                                    <Label>Valid Until</Label><br/>
                                    <Flatpickr data-enable-time value={formData.expiresAt} options={{minuteIncrement: 1}} onChange={date => {
                                        setValue("expiresAt", date)
                                    }} aria-label="Prompt Valid Until or Expires At"/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-9"/>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <Button className="form-control-lg btn-info" onClick={validate}>Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    
                    
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
