import React        from "react";
import BarLoader    from "react-spinners/BarLoader";
import { useQuery } from "urql";

export const CaregiverSelector = ({query, variables, register}) => {
    const [result] = useQuery({
        query,
        variables,
        requestPolicy: "network-only"
    });
    
    const {data, fetching, error} = result;
    
    if (fetching) {
        return (
            <>
                <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
            </>
        );
    } else if (error) {
        return (
            <>
                <p>Oh no... {error.message}</p>
            </>
        );
    } else {
        return (
            <>
                <fieldset>
                    {data.getAllCaregiversOfPatient.map((item, index) => (
                        <label key={item.id}><input type="checkbox" value={item.id} name="caregivers" {...register("caregivers")} defaultChecked={true}/>&nbsp;&nbsp;{item.names.firstName}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    ))}
                </fieldset>
            </>
        );
    }
};
