import React, { useEffect, useState }                                                              from 'react';
import BarLoader                                                                                   from "react-spinners/BarLoader";
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";
import { useMutation, useQuery }                                                                   from "urql";
import voca                                                                                        from 'voca';

// Custom Modules
import Breadcrumbs                             from '../../components/Common/Breadcrumb';
import { CustomDataTable, SelectColumnFilter } from "./CustomDataTable";
import "./datatables.scss";
import { M_DELETE_SPEC, PMSpecificationsForm } from "./PMSpecificationsForm";

const QUERY_GET_ALL_TASKS = `
query GetAllTasks {
    getAllTasks {
        id
        validFrom
        expiresAt
        specificationInteraction {
            id
            coding {
                key
                display
            }
            specification {
                coding {
                    key
                    display
                }
                type
            }
        }
        caregiver {
            names {
                firstName
                lastName
            }
        }
        events {
            createdDate
            caregiver {
                names {
                    firstName
                    lastName
                }
            }
            measure {
                measure {
                    key
                    value
                    description
                }
            }
        }
        patient {
            alias
        }
        notificationJobs {
            sent
            externalID
            externalStatus
        }
    }
}
`;

const getInteractionsData = (events) => {
    let highestValue = -99;
    let description = "No Interaction";
    let caregiver = "n/a";
    let event = events[0];
    for (let i = events.length - 1; i >= 1; i--) {
        let e1 = new Date(event["createdDate"]);
        let e2 = new Date(events[i]["createdDate"]);
        if (e2 > e1) {
            event = events[i];
            highestValue = event["measure"]["measure"].value;
            description = event["measure"]["measure"].description;
            caregiver = event.caregiver.names.firstName + ", " + event.caregiver.names.lastName;
        }
    }
    // console.log({highestValue, description, caregiver});
    return {highestValue, description, caregiver}
}

const getSentData = (data) => {
    console.log(data);
    if (data) {
        let sentData = data["sent"];
        return sentData === true ? "true" : "false"
    }
    return "n/a"
}

const mapQueryResult = (data) => {
    let tableData = [];
    // console.log(data.getAllTasks);
    data.getAllTasks.map(spec => (
        tableData.push({
            id: spec.id,
            validFrom: spec.validFrom,
            expiresAt: spec.expiresAt,
            patient: spec.patient.alias,
            taskID: spec.id,
            // promptKey: spec.coding.key,
            promptID: spec.specificationInteraction.id,
            taskKey: spec.specificationInteraction.coding.key,
            taskDisplay: spec.specificationInteraction.coding.display,
            taskCombined: "(" + spec.specificationInteraction.coding.key + ") " + spec.specificationInteraction.coding.display,
            taskSpecKey: spec.specificationInteraction.specification.coding.key,
            taskSpecDisplay: spec.specificationInteraction.specification.coding.display,
            taskSpecCombined: "(" + spec.specificationInteraction.specification.coding.key + ") " + spec.specificationInteraction.specification.coding.display,
            taskSpecType: spec.specificationInteraction.specification.type,
            interactions: spec.events.length,
            caregiverName: spec.caregiver.names.firstName + ", " + spec.caregiver.names.lastName,
            highestMeasure: getInteractionsData(spec.events).highestValue,
            highestMeasureDescription: getInteractionsData(spec.events).description,
            highestMeasureCaregiver: getInteractionsData(spec.events).caregiver,
            notificationSentToPhone: getSentData(spec.notificationJobs[0]),
            notificationExternalID: spec.notificationJobs[0] && spec.notificationJobs[0]["externalID"] ? spec.notificationJobs[0]["externalID"] : "n/a",
            notificationExternalStatus: spec.notificationJobs[0] && spec.notificationJobs[0]["externalStatus"] ? spec.notificationJobs[0]["externalStatus"] : "n/a"
        })
    ));
    return tableData;
}

const M_CANCEL_TASK = `
mutation deleteTaskNotificationIfNotSent($a: String!) {
  deleteTaskNotificationIfNotSent(arn: $a)}
`;

const POLLING_SECONDS = 45;
const POLLING_INTERVAL = POLLING_SECONDS * 1000;// interval takes milliseconds

const PMTasksDataTable = (props) => {
    
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const [modal, setModal] = useState(false);
    const [specificationToDeleteDisplay, setSpecificationToDeleteDisplay] = useState('');
    const [specificationToDeleteType, setSpecificationToDeleteType] = useState('');
    const [specificationToDeleteID, setSpecificationToDeleteID] = useState('');
    const [deleteSpecResult, deleteSpec] = useMutation(M_DELETE_SPEC);
    const [deleteTaskResult, deleteTask] = useMutation(M_CANCEL_TASK);
    
    // this is a last ditch attempt to re-hydrate from the url location in the event the end user has browser plugins
    // like The Great Suspender that might suspend the tab and then re-hydrate the tab without state information
    const specificationType = props.location.state ? props.location.state.type : props.location.pathname.slice(1, props.location.pathname.length - 1);
    const breadcrumbTitle = voca.titleCase(props.location.pathname.slice(1, props.location.pathname.length));
    
    useEffect(() => {
        setShowDrawer(showDrawer);
    }, [showDrawer])
    
    let tableData = [];
    
    const DataTable = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_ALL_TASKS,
            requestPolicy: "cache-and-network",
            pollInterval: POLLING_INTERVAL,
            variables: {
                type: specificationType
            }
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        
        let infoComponent;
        if (fetching) {
            infoComponent = <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            infoComponent = <p>Oh no... {error.message}</p>;
        } else {
            infoComponent = <></>;
            tableData = mapQueryResult(data);
        }
        
        return (
            <>
                {infoComponent}
                <CustomDataTable columns={PMTasksDataTableColumnConfig} data={tableData}/>
            </>
        );
        
    };
    
    const deleteTaskRowHandler = (data) => {
        deleteTask({a: data.notificationExternalID}).then(() => {
            alert("Task Scheduled To Delete");
        })
    };
    
    const deleteRowHandler = (data, shouldShow, context = 'delete') => {
        data.context = context;
        if (shouldShow) {
            setSpecificationToDeleteID(data.id);
            setSpecificationToDeleteType("Task");
            setSpecificationToDeleteDisplay(data.taskDisplay);
        } else {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
        }
        if (data.interactions <= 1) {
            
            setModal(shouldShow);
        } else {
            alert("You cannot delete a task after it has been interacted with by the caregiver")
        }
    };
    
    const deleteHandler = (id) => {
        deleteSpec({i: id}).then(() => {
            setSpecificationToDeleteID('');
            setSpecificationToDeleteType('');
            setSpecificationToDeleteDisplay('');
            setModal(false);
        })
        
    };
    
    let taskConfig = {
        Header: 'Task',
        columns: [
            {
                Header: 'Task ID',
                accessor: 'taskID'
            }
        ]
    };
    
    let promptColumns = [
        {
            Header: 'Prompt',
            accessor: 'taskCombined'
        },
        {
            Header: 'A/B/C',
            accessor: 'taskSpecCombined'
        }
    ]


// if (DEBUG_MODE) {
//     promptColumns = [{
//         Header: 'Prompt ID',
//         accessor: 'promptID'
//     }, ...promptColumns]
// }
    
    let promptConfig = {
        Header: 'Prompt',
        columns: promptColumns,
    }
    
    const PMTasksDataTableColumnConfig = React.useMemo(
        () => [
            promptConfig,
            {
                Header: 'Schedule',
                columns: [
                    {
                        Header: 'Assigned To',
                        accessor: 'patient',
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    {
                        Header: 'Valid From (UTC)',
                        accessor: 'validFrom'
                    },
                    {
                        Header: 'Valid Until (UTC)',
                        accessor: 'expiresAt'
                    }
                ]
            },
            {
                Header: 'Notification Management',
                columns: [
                    {
                        Header: 'Sent To Phone',
                        accessor: 'notificationSentToPhone'
                    },
                    {
                        Header: 'ExternalID',
                        accessor: 'notificationExternalID'
                    },
                    {
                        Header: 'ExternalStatus',
                        accessor: 'notificationExternalStatus'
                    }
                ]
            },
            {
                Header: 'Interactions',
                columns: [
                    {
                        Header: 'Times Seen',
                        accessor: 'interactions',
                    },
                    {
                        Header: 'Significant Measure',
                        accessor: 'highestMeasureDescription',
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    {
                        Header: 'Associated Caregiver',
                        accessor: 'caregiverName',
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    }
                ]
            }
            ,
            {
                Header: 'Actions',
                columns: [
                    {
                        Header: '',
                        accessor: 'actions',
                        Cell: ({cell: {value, ...props}}) => {
                            
                            let canBeRevoked = props.row.original.notificationExternalID && props.row.original.notificationExternalID.length > 5 && props.row.original.interactions === 0 && (props.row.original.notificationExternalStatus === "RUNNING" || props.row.original.notificationExternalStatus === "NA");
                            
                            if (canBeRevoked) {
                                return (
                                    <React.Fragment>
                                        <Row>
                                            
                                            <Col className="col-6 col-sm">
                                                <button className="btn btn-danger btn-sm waves-effect waves-light mr-1" onClick={() => {
                                                    deleteTaskRowHandler(props.row.original);
                                                }}>
                                                    <i className="mdi mdi-trash-can-outline mdi-18px"/>
                                                    <span style={{textDecoration: "none", color: "white"}} href="#" id="RevokeToolTip"> Revoke Task</span>
                                                    <UncontrolledTooltip placement="bottom" target="RevokeToolTip">
                                                        Click here to Revoke this Task
                                                    </UncontrolledTooltip>
                                                </button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment>
                                        <Row>
                                            <Col className="col-6 col-sm">
                                                <button className="btn btn-outline-info btn-sm waves-effect waves-light mr-1" onClick={() => {
                                                    alert("This task has already been sent to the Caregiver's phone and cannot be revoked")
                                                }} disabled={true}>
                                                    <span style={{textDecoration: "none", color: "grey"}} href="#" id="FalseRevokeToolTip"> Cannot Revoke Task</span>
                                                    <UncontrolledTooltip placement="bottom" target="FalseRevokeToolTip">
                                                        This task has been sent to phone (and possibly interacted with) cannot be revoked
                                                    </UncontrolledTooltip>
                                                </button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            }
                        },
                        Filter: () => {
                            return null;
                        }
                    }
                ],
                minWidth: 175,
            }
        ],
        [promptConfig, taskConfig]
    );
    
    let drawerComponent = '';
    let drawerOverlayComponent = '';
    if (showDrawer) {
        drawerComponent = (
            <PMSpecificationsForm content={drawerContent} closeFxn={() => {
                setShowDrawer(false);
            }}/>
        )
        drawerOverlayComponent = (
            <div className="drawer-overlay"></div>
        )
    }
    
    return (
        <React.Fragment>
            {drawerOverlayComponent}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem="Specification Management"/>
                    {drawerComponent}
                    <Row>
                        <Col className="col-12 ">
                            <Card>
                                {/*<CardBody>*/}
                                <div className={"table-responsive"}>
                                    <DataTable/>
                                </div>
                                {/*</CardBody>*/}
                            </Card>
                        </Col>
                    </Row>
                    
                    
                    <Modal isOpen={modal} toggle={() => {
                        setModal(!modal)
                    }}>
                        <ModalHeader toggle={() => {
                            setModal(!modal)
                        }} close={() => {
                            setModal(!modal)
                        }}>Confirm Delete</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete
                            the {`${specificationToDeleteType}`}:<br/><b>{`${specificationToDeleteDisplay}`}</b>?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => {
                                deleteHandler(specificationToDeleteID)
                            }}>Confirm Delete</Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setModal(!modal)
                            }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                
                </div>
            </div>
        </React.Fragment>
    );
}

export default PMTasksDataTable;
