import { FronteggProvider } from '@frontegg/react';
import React                from 'react';
import ReactDOM             from 'react-dom';
import { Provider }         from 'react-redux';
import { BrowserRouter }    from 'react-router-dom';
import App                  from './App';
import './i18n';
import * as serviceWorker   from './serviceWorker';
import store                from './store';

// accessibility testing
const axe = require('@axe-core/react');
axe(React, ReactDOM, 5000).then((result) => {
    console.log(result);
    console.log("Accessibility Test Ran")
});

const fronteggOptions = {
    baseUrl: 'https://app-nzb5ht43h3cn.frontegg.com',
};

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <FronteggProvider contextOptions={fronteggOptions}>
                <App/>
            </FronteggProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
