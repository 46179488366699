import * as moment                        from "moment";
import React, { Component }               from 'react';
import ReactApexChart                     from 'react-apexcharts';
import { Card, CardBody, CardTitle, Row } from "reactstrap";

class SocialSource extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            
            series: [
                {
                    name: 'People Group 1',
                    data: [
                        {
                            x: 'Study Group 1',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-07').getTime()
                            ]
                        },
                        {
                            x: 'Study Group 2',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-10').getTime()
                            ]
                        },
                        {
                            x: 'Study Group 3',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-09').getTime()
                            ]
                        }
                    ]
                },
                {
                    name: 'People Group 2',
                    data: [
                        {
                            x: 'Study Group 1',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-08').getTime()
                            ]
                        },
                        {
                            x: 'Study Group 2',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-12').getTime()
                            ]
                        },
                        {
                            x: 'Study Group 3',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-13').getTime()
                            ]
                        }
                    ]
                }
            ],
            options: {
                chart: {
                    height: 800,
                    width: '100%',
                    type: 'rangeBar'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 2,
                        horizontal: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        let a = moment(val[0])
                        let b = moment(val[1])
                        let diff = Math.round(b.diff(a, 'mins') / 6000000)
                        return diff + (diff > 1 ? ' mins' : ' min')
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 0.25,
                        gradientToColors: undefined,
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [50, 0, 100, 100]
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false,
                    }
                    
                },
                legend: {
                    position: 'bottom'
                }
            },
            
            
        };
    }
    
    render() {
        
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            Average Time to First Response
                        </CardTitle>
                        
                        <Row className="mb-1">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="rangeBar" height={350}/>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SocialSource;
