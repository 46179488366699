import "flatpickr/dist/themes/material_green.css";
import React                               from 'react';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';


export const PMTaskViewForm = (props) => {
    
    console.log("props", props);
    
    return (
        <Row>
            <Col>
                <Card className="shadow-none">
                    <CardBody>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Patient Alias</Label><br/>
                                    <span>{props.selectedPatientAlias}</span>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Prompt (Caregiver: Relationship)</Label><br/>
                                    <span>{props.event.title}</span>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Send Time</Label><br/>
                                    <span>{props.event.start.toLocaleString()}</span>
                                </div>
                            </Col>
                        </Row>
                    
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
