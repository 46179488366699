import React, { useEffect, useState } from "react";
import { Redirect }                   from "react-router-dom";
import BarLoader                      from "react-spinners/BarLoader";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row
}                                     from "reactstrap";
import { useMutation, useQuery }      from "urql";
import {
    FORM_CONTEXT_SKILL_CREATE,
    SPEC_TYPE_SKL
}                                     from "../../components/Common/Constants";
import { initialFormState }           from "../Forms/SpecificationForm";

const POLLING_INTERVAL = 5 * 60 * 25;
const Q_GET_SPEC_BY_KEY = `
query getSpecificationByKey($key: String!) {
    getSpecificationByKey(key: $key) {
        id
        coding {
            key
        }
    }
}
`;

const M_REGISTER_PROMPT = `
mutation registerSpecificationInteraction ($specificationID: String!, $enabled: Boolean!, $key: String!, $display: String!) {
  registerSpecificationInteraction (specificationID: $specificationID, enabled: $enabled, key: $key, display: $display)}
`;

export const M_DELETE_SPEC = `
mutation  deleteSpecification($i: String!) {
  deleteSpecification(id: $i)}
`;

export const PMPromptForm = (props) => {
    
    const [content, setContent] = useState({});
    
    const [id, setId] = useState(content.id);
    const [key, setKey] = useState(content.key);
    const [display, setDisplay] = useState(content.display);
    
    const [registerPromptResult, registerPrompt] = useMutation(M_REGISTER_PROMPT);
    
    const [redirect, setRedirect] = useState(<></>);
    
    useEffect(() => {
        setKey(content.key);
        setDisplay(content.display);
    }, [content]);
    
    const resetForm = () => {
        setContent({});
        setKey("");
        setDisplay("");
    }
    
    const onSubmit = (navigateAway = false) => {
        let variables = {
            specificationID: selectedSkill,
            key: key,
            display: display || '',
            enabled: true
        };
        
        console.log(variables);
        
        registerPrompt(variables).then(result => {
            console.log(result);
            if (result.data.registerSpecificationInteraction === true) {
                alert("Prompt Created!")
                resetForm();
                setRedirect(<Redirect to={"prompts"}/>);
            }
            resetForm();
        });
    };
    
    const attrKeyChange = (event) => {
        setKey(event.target.value);
    }
    
    const attrDisplayChange = (event) => {
        setDisplay(event.target.value);
    }
    
    const [selectedSkill, setSelectedSkill] = useState('00000000-0000-0000-0000-000000000000');
    const [selectedSkillDisplay, setSelectedSkillDisplay] = useState('');
    const [skillSelectOpen, setSkillSelectOpen] = useState(false);
    const toggleSkillSelect = () => setSkillSelectOpen(prevState => !prevState);
    
    
    const QUERY_GET_SPECIFICATIONS = `
query {
    getAllSpecificationsByType(type: "skill") {
        id
        coding {
            key
            display
            description
        }
        sorting {
            weight
        }
        type
    }
}
`;
    
    
    const SkillsSelector = () => {
        const [result, refreshQuery] = useQuery({
            query: QUERY_GET_SPECIFICATIONS,
            requestPolicy: "network-only"
        });
        
        const refresh = () => {
            refreshQuery({requestPolicy: 'network-only'});
        };
        
        const {data, fetching, error} = result;
        if (fetching) {
            return (
                <>
                    <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
                </>
            );
        } else if (error) {
            return (
                <>
                    <p>Oh no... {error.message}</p>
                </>
            );
        } else {
            return (
                <>
                    <Dropdown isOpen={skillSelectOpen} toggle={toggleSkillSelect}>
                        <DropdownToggle caret className="btn-block">
                            {selectedSkillDisplay !== '' ? selectedSkillDisplay : 'Select Skill...'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {data.getAllSpecificationsByType.map((item, index) => (
                                <DropdownItem key={index} onClick={() => {
                                    setSelectedSkill(item.id);
                                    setSelectedSkillDisplay(item.coding.display);
                                }}>{item.coding.display} ({item.coding.key})</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </>
            );
        }
    };
    
    return (
        <Card className="drawer">
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card className="shadow-none">
                            <CardBody>
                                <Row>
                                    <Col className="col-11">
                                        <CardTitle>Add Prompt</CardTitle>
                                    </Col>
                                    {/*<Col className="col-1">*/}
                                    {/*    {closeButtonComponent}*/}
                                    {/*</Col>*/}
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Prompt Key</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="text"
                                                minLength="4"
                                                maxLength="64"
                                                placeholder="Enter Prompt Unique Key, e.g PMT.001"
                                                defaultValue={key}
                                                onChange={(e) => {
                                                    attrKeyChange(e);
                                                }}
                                                name="key"
                                                id="key"
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Prompt Display (Shows In Notification)</Label>
                                            <Input
                                                className="form-control-lg"
                                                type="textarea"
                                                minLength="4"
                                                maxLength="4096"
                                                placeholder="Enter the Prompt Display e.g. clean your room"
                                                defaultValue={display}
                                                onChange={(e) => {
                                                    attrDisplayChange(e);
                                                }}
                                                name="display"
                                                id="display"
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-9">
                                        <div className="mt-3">
                                            <Label>Prompt Associated Skill</Label>
                                            <SkillsSelector/>
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                    </Col>
                                </Row>
                                
                                <Row>&nbsp;</Row>
                                
                                <Button className="form-control-lg btn-info" onClick={() => onSubmit()}>Submit</Button>
                                {redirect}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
};
