import classnames          from 'classnames';
import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
}                          from "reactstrap";

//Import Images
import img1 from "../../assets/images/product/img-1.png"
import img7 from "../../assets/images/product/img-7.png"

//Import Breadcrumb
import Breadcrumbs              from '../../components/Common/Breadcrumb';
import {
    FORM_CONTEXT_SKILL_CREATE,
    FORM_CONTEXT_SPEC_CREATE,
    SPEC_TYPE_ANT,
    SPEC_TYPE_BHV,
    SPEC_TYPE_CSQ,
    SPEC_TYPE_SKL
}                               from "../../components/Common/Constants";
import { PMAssociationBuilder } from "../Tables/PMAssociationBuilder";

import { PMSpecificationsForm } from "../Tables/PMSpecificationsForm";


const optionGroup = [
    {
        label: "Picnic",
        options: [
            {label: "Mustard", value: "Mustard"},
            {label: "Ketchup", value: "Ketchup"},
            {label: "Relish", value: "Relish"}
        ]
    },
    {
        label: "Camping",
        options: [
            {label: "Tent", value: "Tent"},
            {label: "Flashlight", value: "Flashlight"},
            {label: "Toilet Paper", value: "Toilet Paper"}
        ]
    }
];

const orderSummary = [
    {id: 1, img: img1, productTitle: "Half sleeve T-shirt (64GB)", price: 450, qty: 1},
    {id: 2, img: img7, productTitle: "Wirless Headphone", price: 225, qty: 1}
];

export const initialFormState = {
    "id": "0000-0000-0000",
    "key": "",
    "display": "",
    "description": "",
    "weight": 0,
    "type": SPEC_TYPE_ANT,
    "associations": 0,
    "actions": "",
    "context": FORM_CONTEXT_SPEC_CREATE
};


const SpecificationForm = (props) => {
    
    const types = [SPEC_TYPE_ANT, SPEC_TYPE_BHV, SPEC_TYPE_CSQ, SPEC_TYPE_SKL];
    
    const [activeTab, setactiveTab] = useState('1');
    const [selectedGroup, setselectedGroup] = useState(null);
    
    const specificationType = props.location.state ? props.location.state.type : false;
    
    let t = initialFormState;
    let breadcrumbTitle = 'Add Specification';
    let breadcrumbItem = 'Specification Management';
    if(specificationType !== false && types.includes(specificationType)){
        t = {...initialFormState, type: specificationType};
        if(specificationType === SPEC_TYPE_SKL){
            t = {...t, context: FORM_CONTEXT_SKILL_CREATE};
            breadcrumbTitle = 'Add Skill';
            breadcrumbItem = 'Skill Management';
        }
    }
    
    const [createFormInitialState, setCreateFormInitialState] = useState(JSON.stringify(t));
    
    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbItem} />
                    
                    <div className="checkout-tabs">
                        <Row>
                            <Col lg="2">
                                <Nav className="flex-column" pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                setactiveTab('1');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">Details</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => {
                                                setactiveTab('2');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">A/B/C  &lt;=&gt; Skill Association</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '3'})}
                                            onClick={() => {
                                                setactiveTab('3');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">User &lt;=&gt; Skill Association</p>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col lg="10">
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <div>
                                                    <PMSpecificationsForm content={createFormInitialState} closeFxn={() => {}} />
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                                                <div>
                                                    <PMAssociationBuilder content={createFormInitialState} closeFxn={() => {}} />
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                                                <div>
                                                    <PMAssociationBuilder content={createFormInitialState} closeFxn={() => {}} />
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                                <Row className="my-4">
                                    {/*<Col sm="6">*/}
                                    {/*    <Link to="/ecommerce-cart" className="btn text-muted d-none d-sm-inline-block btn-link">*/}
                                    {/*        <i className="mdi mdi-arrow-left mr-1"></i> Back to Shopping Cart </Link>*/}
                                    {/*</Col>*/}
                                    {/*<Col sm="6">*/}
                                    {/*    <div className="text-sm-right">*/}
                                    {/*        <Link to="/skills" className="btn btn-success">*/}
                                    {/*            <i className="mdi mdi-truck-fast mr-1"></i> Proceed to Skills Management </Link>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SpecificationForm;
