import React          from 'react';
import ReactApexChart from 'react-apexcharts';
import BarLoader      from "react-spinners/BarLoader";
import { useQuery }   from "urql";

const Colors = {
    light: {
        text: "#000",
        textAlt: "#111",
        background: "#f7f8fc",
        tabIconDefault: "#eee",
        blue: "rgba(0,122,255,1.0)",
        green: "rgba(52,199,89,1.0)",
        indigo: "rgba(88,86,214,1.0)",
        orange: "rgb(255,185,0)",
        pink: "rgba(255,45,85,1.0)",
        purple: "rgba(175,82,222,1.0)",
        red: "rgba(255,59,48,1.0)",
        teal: "rgba(90,200,250,1.0)",
        yellow: "rgba(255,204,0,1.0)",
        gray: "rgba(142,142,147,1.0)",
        gray2: "rgba(174,174,179,1.0)",
        gray3: "rgba(199,199,204,1.0)",
        gray4: "rgba(209,209,214,1.0)",
        white: "rgba(255,255,255,1.0)",
    }
};

const Q_GetTotalTasksInEachResponseMeasureThisMonthChart = `
query getTotalTasksInEachResponseMeasureThisMonth {
  getTotalTasksInEachResponseMeasureThisMonth {
    data
  }
}
`;


const StackedColumnChart = (props) => {
    const graphOptions = {
        options: {
            chart: {
                height: 359,
                type: "bar",
                stacked: !0,
                toolbar: {
                    show: 1
                },
                zoom: {
                    enabled: !0
                }
            },
            plotOptions: {
                bar: {
                    horizontal: !1,
                    columnWidth: "18%"
                }
            },
            dataLabels: {
                enabled: !1
            },
            xaxis: {
                categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15"]
            },
            colors: ["#469990", "#000075", "#42d4f4", "#4363d8", "#911eb4", "#dcbeff"],
            legend: {
                position: "bottom"
            },
            fill: {
                opacity: 1
            }
        }
    }
    
    
    const TotalTasksInEachResponseMeasureThisMonthChart = () => {
        const [result] = useQuery({
            query: Q_GetTotalTasksInEachResponseMeasureThisMonthChart,
            requestPolicy: "network-only",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find data</p>;
        } else {
            let dataAsObject = JSON.parse(data.getTotalTasksInEachResponseMeasureThisMonth.data);
            let seen = [];
            let series = [];
            let dt = new Date();
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            let daysInMonth = new Date(year, month, 0).getDate();
    
            let optionCategories = [];
            for (let dim = 0; dim < daysInMonth; dim++) {
                optionCategories.push(String(dim+1).padStart(2, '0'));
            }
            graphOptions.options.xaxis.categories = optionCategories;
            
            
            
            for (let i = 0; i < dataAsObject.length; i++) {
                if(!seen.includes(dataAsObject[i].description)){
                    let seriesData = [];
                    for (let dim = 0; dim < daysInMonth; dim++) {
                        seriesData.push(0)
                    }
                    series.push({name:dataAsObject[i].description, data:seriesData})
                    seen.push(dataAsObject[i].description);
                }
            }
            for (let i = 0; i < dataAsObject.length; i++) {
                const dateParsed = new Date(dataAsObject[i].createdDate);
                const entryBucket = dataAsObject[i].description;
                const entryDay = dateParsed.getDate() - 1;
                for (let j = 0; j < series.length; j++) {
                    const seriesBucket = series[j];
                    if(seriesBucket.name === entryBucket){
                        seriesBucket.data[entryDay] +=1
                    }
                }
                
            }
            return (
                <ReactApexChart options={graphOptions.options} series={series} type="bar" height="359"/>
            );
        }
    };
    
    return (
        <React.Fragment>
            <TotalTasksInEachResponseMeasureThisMonthChart/>
        </React.Fragment>
    );
}

export default StackedColumnChart;
