import classnames          from 'classnames';
import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
}                          from "reactstrap";

import Breadcrumbs              from '../../components/Common/Breadcrumb';
import {
    FORM_CONTEXT_SKILL_CREATE,
    FORM_CONTEXT_SPEC_CREATE,
    SPEC_TYPE_ANT,
    SPEC_TYPE_BHV,
    SPEC_TYPE_CSQ,
    SPEC_TYPE_SKL
}                                         from "../../components/Common/Constants";
import { PMAssociationBuilder }           from "../../pages/Tables/PMAssociationBuilder";
import { PMSpecificationsForm }           from "../../pages/Tables/PMSpecificationsForm";
import { PMUserCaregiverForm }            from "./PMUserCaregiverForm";
import { PMUserPatientCaregiverLinkForm } from "./PMUserPatientCaregiverLinkForm";
import { PMUserPatientForm }              from "./PMUserPatientForm";
import { PMUserPhysicianForm }            from "./PMUserPhysicianForm";


export const initialFormState = {
    "id": "0000-0000-0000",
    "key": "",
    "display": "",
    "description": "",
    "weight": 0,
    "type": SPEC_TYPE_ANT,
    "associations": 0,
    "actions": "",
    "context": FORM_CONTEXT_SPEC_CREATE
};


const PMUserFormContainer = (props) => {
    
    const types = [SPEC_TYPE_ANT, SPEC_TYPE_BHV, SPEC_TYPE_CSQ, SPEC_TYPE_SKL];
    
    const [activeTab, setactiveTab] = useState('1');
    
    const specificationType = props.location.state ? props.location.state.type : false;
    
    let t = initialFormState;
    let breadcrumbTitle = 'Add User';
    let breadcrumbItem = 'User Management';
    if(specificationType !== false && types.includes(specificationType)){
        t = {...initialFormState, type: specificationType};
        if(specificationType === SPEC_TYPE_SKL){
            t = {...t, context: FORM_CONTEXT_SKILL_CREATE};
            breadcrumbTitle = 'Add Skill';
            breadcrumbItem = 'Skill Management';
        }
    }
    
    const [createFormInitialState, setCreateFormInitialState] = useState(JSON.stringify(t));
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={breadcrumbTitle} breadcrumbItem={breadcrumbItem} />
                    
                    <div className="checkout-tabs">
                        <Row>
                            <Col lg="2">
                                <Nav className="flex-column" pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                setactiveTab('1');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">Provider</p>
                                        </NavLink>
                                    </NavItem>
                                    
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => {
                                                setactiveTab('2');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">Caregiver</p>
                                        </NavLink>
                                    </NavItem>
    
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '3'})}
                                            onClick={() => {
                                                setactiveTab('3');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">Patient</p>
                                        </NavLink>
                                    </NavItem>
                                    
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '4'})}
                                            onClick={() => {
                                                setactiveTab('4');
                                            }}
                                        >
                                            <i className="bx d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">Link Patient to Caregiver</p>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col lg="10">
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1" className="col-12">
                                                <div>
                                                    <PMUserPhysicianForm />
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                                                <div>
                                                    <PMUserCaregiverForm />
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                                                <div>
                                                    <PMUserPatientForm />
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="4" id="v-pills-confir" role="tabpanel">
                                                <div>
                                                    <PMUserPatientCaregiverLinkForm />
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                                <Row className="my-4">
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PMUserFormContainer;
