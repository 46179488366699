import React                                                 from 'react';
import { CustomDataTable, SelectColumnFilter }               from "./CustomDataTable";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

const editRowHandler = (data) => {
    console.log("editRowHandler");
    console.log(data);
};


const DatatableTables = (props) => {
    
    
    const users = [
        {
            "id": "9b091f76-8596-4939-8f55-5953a8c5ead7",
            "firstName": "Stefan",
            "lastName": "Khan-Kernahan",
            "role": "physician",
            "alias": "",
            "emailOrPhone": "stefan@stefankk.ca",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "4355607f-d32c-4dd2-ad16-c07970ac0808",
            "firstName": "Jon",
            "lastName": "Perle",
            "role": "physician",
            "alias": "",
            "emailOrPhone": "jonperle@gmail.com",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "80e16f20-3432-46ec-8432-6713cc69fa19",
            "firstName": "Father in Couple",
            "lastName": "Of One Child",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "couple1@pmt.test",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "d85a230f-78ca-4bb0-87ae-33a31251a730",
            "firstName": "Mother in Couple",
            "lastName": "Of One Child",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "couple1.mom@pmt.test",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "0f9c2a2c-dd14-4b98-8d59-481ea1e798fb",
            "firstName": "Father",
            "lastName": "Of Two Children",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "dad2@pmt.test",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "5be3e677-7dfa-44a2-b36b-6fcb35ddb871",
            "firstName": "Mother",
            "lastName": "Of One Child",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "mom1@pmt.test",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "c35e8d3e-a8cc-4ad4-b74b-18670acc8c16",
            "firstName": "Mother",
            "lastName": "Of Two Children",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "mom2@pmt.test",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "a030f0f3-9d5d-4a84-b003-530ade2db7e7",
            "firstName": "Stefan",
            "lastName": "KK",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "stefan@pmt.ca",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "98258b1a-0020-46a3-89f8-9469b3deb4a3",
            "firstName": "Jon",
            "lastName": "Perle",
            "role": "caregiver",
            "alias": "",
            "emailOrPhone": "demo@pmt.ca",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "5002ea1f-5f29-46e7-b0d9-523ca181eb2f",
            "firstName": "Clark",
            "lastName": "Kent",
            "role": "Patient",
            "alias": "clark",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "3a11d1b3-5a61-4381-b864-02f65df17c6c",
            "firstName": "Son",
            "lastName": "Of Couple",
            "role": "Patient",
            "alias": "Son of Couple",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "d054a5bc-11fa-4244-941e-6371fb58ad7b",
            "firstName": "Son One",
            "lastName": "Of Mom of Two",
            "role": "Patient",
            "alias": "Son One of Mom of Two Children",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "674f5bf6-f504-4aef-9892-1cb6971a4e61",
            "firstName": "Son",
            "lastName": "Of Mom of One",
            "role": "Patient",
            "alias": "Son of Mom of One Child",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "579c525c-7058-4f05-bf8e-ca487c758df9",
            "firstName": "Son One",
            "lastName": "Of Dad of Two",
            "role": "Patient",
            "alias": "Son One of Dad of Two Children",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "ceea34ed-2556-497c-adf3-e79f6bea6d61",
            "firstName": "Son Two",
            "lastName": "Of Dad of Two",
            "role": "Patient",
            "alias": "Son Two of Dad of Two Children",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }, {
            "id": "77ec2f4f-70c9-4ab0-81fe-4a55e064ed56",
            "firstName": "Son Two",
            "lastName": "Of Mom of Two",
            "role": "Patient",
            "alias": "Son Two of Mom of Two Children",
            "emailOrPhone": "",
            "usePhone": "",
            "useToken": "",
            "__typename": "UserResource"
        }];
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Contact',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                    },
                    {
                        Header: 'Alias',
                        accessor: 'alias',
                    }
                ]
            },
            {
                Header: 'Records',
                columns: [
                    {
                        Header: 'Role',
                        accessor: 'role',
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                    },
                    {
                        Header: 'Progress',
                        accessor: 'progress',
                    }
                ]
            },
            {
                Header: 'Actions',
                columns: [
                    {
                        Header: '',
                        accessor: 'edit',
                        Cell: ({cell: {value, ...props}}) => {
                            return <button className="btn btn-primary btn-sm waves-effect waves-light" onClick={() => {
                                editRowHandler(props.row.original)
                            }}><i className="mdi mdi-account-edit-outline"></i></button>;
                        },
                        Filter: () => {
                            return null;
                        }
                    },
                    {
                        Header: '',
                        accessor: 'delete',
                        Cell: ({cell: {value, ...props}}) => {
                            return <button className="btn btn-danger btn-sm waves-effect waves-light" onClick={() => {
                                editRowHandler(props.row.original)
                            }}><i className="mdi mdi-trash-can-outline"></i></button>;
                        },
                        Filter: () => {
                            return null;
                        }
                    }
                ]
            }
        ],
        []
    );
    
    const data = {
        maxHeight: '100%',
        columns: [
            {
                label: "First Name",
                field: "firstName",
                sort: "asc",
                width: 150,
                searchable: true
            },
            {
                label: "Last Name",
                field: "lastName",
                sort: "asc",
                width: 150,
                searchable: true
            },
            {
                label: "Alias",
                field: "alias",
                sort: "asc",
                width: 150,
                searchable: true
            },
            {
                label: "Role",
                field: "role",
                sort: "asc",
                width: 150,
                searchable: true
            },
            {
                label: "Contact",
                field: "emailOrPhone",
                sort: "asc",
                width: 200,
                searchable: true
            }
        ],
        rows: users
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="Data Tables"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>All Users</CardTitle>
                                    <div className={"table-responsive"}>
                                        <CustomDataTable columns={columns} data={users}></CustomDataTable>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DatatableTables;
