import React                                   from 'react';
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

const TopCities = (props) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">
                        Log Submissions Count per Caregiver
                    </CardTitle>
                    {/* Measure Feature Utilization
                    {/*<div className="text-center">*/}
                    {/*    <div className="mb-4">*/}
                    {/*        <i className="bx bx-map-pin text-primary display-4"></i>*/}
                    {/*    </div>*/}
                    {/*    <h3>1,456</h3>*/}
                    {/*    <p>San Francisco</p>*/}
                    {/*</div>*/}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default TopCities;
