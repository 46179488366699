import React                                      from 'react';
import { connect }                                from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createClient, Provider }                 from 'urql';

import "./assets/scss/theme.scss";
import NonAuthLayout                              from "./components/NonAuthLayout";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import { authRoutes, userRoutes } from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";

export const DEBUG_MODE = false;

let api_url;
if(DEBUG_MODE){
    api_url = 'http://localhost:4000/graphql';
} else {
    api_url = 'https://pmt-helper-api.onrender.com/graphql';
}
export const API_URL = api_url;

const client = createClient({
    url: API_URL,
    fetchOptions: () => {
        return {
            headers: {},
        };
        //
        // const token = getToken();
        // return {
        //     headers: {authorization: token ? `Bearer ${token}` : ''},
        // };
    },
});

const App = (props) => {
    
    function getLayout() {
        return VerticalLayout;
    }
    
    const Layout = getLayout();
    
    const NonAuthmiddleware = ({component: Component, layout: Layout}) => (
        <Route
            render={props => {
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
    
    return (
        <React.Fragment>
            <Provider value={client}>
                <Router>
                    <Switch>
                        {authRoutes.map((route, idx) => (
                            <NonAuthmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx}/>
                        ))}
                        
                        {userRoutes.map((route, idx) => (
                            <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx}/>
                        ))}
                    </Switch>
                </Router>
            </Provider>
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        layout: state.Layout,
        showRightbar: false
    };
};

export default connect(mapStateToProps, null)(App);
