import React                                                                            from 'react'
import { useTable, useSortBy, useFilters, useColumnOrder, useRowSelect, usePagination } from 'react-table'
import { motion, AnimatePresence }                                                      from 'framer-motion'
import * as matchSorter                                                                 from 'match-sorter'

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter},}) {
    const count = preFilteredRows.length;
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} results...`}
            className={"form-control border-top-0 border-right-0 border-left-0"}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({column: {filterValue, setFilter, preFilteredRows, id},}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        });
        return [...options.values()]
    }, [id, preFilteredRows]);
    
    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            className={"form-control"}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({column: {filterValue, setFilter, preFilteredRows, id},}) {
    // Calculate the min and max
    // using the preFilteredRows
    
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max)
        });
        return [min, max]
    }, [id, preFilteredRows]);
    
    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({column: {filterValue = [], preFilteredRows, setFilter, id},}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max)
        });
        return [min, max]
    }, [id, preFilteredRows]);
    
    return (
        <div style={{display: 'flex',}}>
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function shuffle(arr) {
    arr = [...arr];
    const shuffled = [];
    while (arr.length) {
        const rand = Math.floor(Math.random() * arr.length);
        shuffled.push(arr.splice(rand, 1)[0])
    }
    return shuffled
}

export function CustomDataTable({columns, data}) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );
    
    const getRowId = React.useCallback(row => {
        return row.id
    }, [])
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: {pageIndex, pageSize, ...state},
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: 0,
                pageSize: 20
            },
            getRowId,
        },
        useColumnOrder,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        // Here we will use a plugin to add our selection column
        hooks => {
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        id: 'selection',
                        groupByBoundary: true,
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ]
            })
        }
    );
    
    const spring = React.useMemo(
        () => ({
            type: 'spring',
            damping: 500,
            stiffness: 5000,
        }),
        []
    );
    
    return (
        <>
            <table {...getTableProps()} className="table table-bordered table-striped table-full-height datatable">
                <thead>
                {headerGroups.map((headerGroup, i) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <motion.th
                                {...column.getHeaderProps({
                                    layoutTransition: spring,
                                    style: {
                                        minWidth: column.minWidth,
                                    },
                                })}
                            >
                                <div {...column.getSortByToggleProps()}  className={"text-center"}>
                                    {column.render('Header')}
                                    <span>{column.isSorted ? column.isSortedDesc ? " \u2193" : ' \u2191' : ''}</span>
                                </div>
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </motion.th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                <AnimatePresence>
                    {/*{rows.slice(0, 25).map((row, i) => {*/}
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <motion.tr
                                {...row.getRowProps({
                                    layoutTransition: spring,
                                    exit: {opacity: 0, maxHeight: 0},
                                })}
                            >
                                {row.cells.map((cell, i) => {
                                    return (
                                        <motion.td
                                            {...cell.getCellProps({
                                                layoutTransition: spring,
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </motion.td>
                                    )
                                })}
                            </motion.tr>
                        )
                    })}
                </AnimatePresence>
                </tbody>
            </table>

            {/*<div className="pagination">*/}
            <ul data-test="pagination" className="pagination float-left">
                <li data-test="page-item" className="page-item">
                    <a data-test="page-link" aria-label="Previous" className="page-link page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}><span>{'<<'}</span></a>
                </li>
                <li data-test="page-item" className="page-item">
                    <a data-test="page-link" aria-label="Previous" className="page-link page-link" onClick={() => previousPage()} disabled={!canPreviousPage}><span>{'<'}</span></a>
                </li>
                <li data-test="page-item" className="page-item">
                    <a data-test="page-link" aria-label="Next" className="page-link page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        <span>{'>'}</span>
                    </a>
                </li>
                <li data-test="page-item" className="page-item">
                    <a data-test="page-link" aria-label="Next" className="page-link page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <span>{'>>'}</span>
                    </a>
                </li>
            </ul>
            <ul data-test="pagination" className="pagination float-right">
                <li data-test="page-item" className="page-item border-0">
                    <span className={"form-control border-0 ml-15"}>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
                </li>
                <li data-test="page-item" className="page-item border-0">
                    <select value={pageSize} onChange={e => {setPageSize(Number(e.target.value))}} className={"form-control"} aria-label="Show Number of Results Per Page">
                        {[10, 20, 30, 40, 50].map(pageSize => (<option key={pageSize} value={pageSize}>Show {pageSize}</option>))}
                    </select>
                </li>
            </ul>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number';

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;
        
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate]);
        
        return (
            <>
                <input type="checkbox" aria-label="Toggle Row Selected" ref={resolvedRef} {...rest} />
            </>
        )
    }
);

