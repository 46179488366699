import React, { useState }                                           from "react";
import { useForm }                                                   from "react-hook-form";
import { Redirect }                                                  from "react-router-dom";
import BarLoader                                                     from "react-spinners/BarLoader";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { useMutation, useQuery }                                     from "urql";
import "../../assets/scss/custom.scss"


const M_LINK_CAREGIVER_TO_PATIENT = `
mutation linkCaregiverToPatient($relationshipID: String!, $caregiverID: String!, $patientID: String!) {
  linkCaregiverToPatient(relationshipID: $relationshipID, caregiverID: $caregiverID, patientID: $patientID)
 }
`;

export const PMUserPatientCaregiverLinkForm = (props) => {
    
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    
    const [registerRelationshipResult, registerRelationship] = useMutation(M_LINK_CAREGIVER_TO_PATIENT);
    const [redirect, setRedirect] = useState(<></>);
    
    const onSubmit = (data) => {
        console.log(data);
        registerRelationship(data).then(result => {
            if (result.data && result.data.linkCaregiverToPatient === true) {
                alert("Patient-Caregiver Association Successfully Created");
                setRedirect(<Redirect to={"users"} />)
            } else {
                alert("Could not create Patient-Caregiver Association.")
            }
        });
    }
    
    const validate = () => {
        console.log(errors);
        handleSubmit(onSubmit)();
    }
    
    const onRelationshipSelected = (e) => {
        console.log(e.target.value);
        setValue("relationshipID", e.target.Value);
    }
    
    const RelationshipDropdown = () => {
        const [result] = useQuery({
            query: `query relationships { relationships { id, alias}}`,
            requestPolicy: "cache-first",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find user relationships</p>;
        } else {
            let d = data.relationships ? data.relationships : [];
            if (d.length !== 0) {
                return (
                    <select name="timezones" onChange={onRelationshipSelected} className={"form-control form-control-lg"} {...register("relationshipID", {required: true})}>
                        {d.map(e => (
                            <option key={e.id} value={e.id}>
                                {e.alias}
                            </option>
                        ))}
                    </select>
                );
            }
        }
        return <></>;
    };
    
    const onCaregiverSelected = (e) => {
        console.log(e.target.value);
        setValue("caregiverID", e.target.Value);
    }
    
    const CaregiverDropdown = () => {
        const [result] = useQuery({
            query: `query getAllCaregivers { getAllCaregivers { id, names { firstName, lastName }}}`,
            requestPolicy: "network-only",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find caregivers</p>;
        } else {
            let d = data.getAllCaregivers ? data.getAllCaregivers : [];
            if (d.length !== 0) {
                return (
                    <select name="timezones" onChange={onCaregiverSelected} className={"form-control form-control-lg"} {...register("caregiverID", {required: true})}>
                        {d.map(e => (
                            <option key={e.id} value={e.id}>
                                {e.names.lastName}, {e.names.firstName}
                            </option>
                        ))}
                    </select>
                );
            }
        }
        return <></>;
    };
    
    const onPatientSelected = (e) => {
        console.log(e.target.value);
        setValue("patientID", e.target.Value);
    }
    
    const PatientDropdown = () => {
        const [result] = useQuery({
            query: `query getAllPatients { getAllPatients { id, alias, names { firstName, lastName }}}`,
            requestPolicy: "network-only",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find patients</p>;
        } else {
            let d = data.getAllPatients ? data.getAllPatients : [];
            if (d.length !== 0) {
                return (
                    <select name="timezones" onChange={onPatientSelected} className={"form-control form-control-lg"} {...register("patientID", {required: true})}>
                        {d.map(e => (
                            <option key={e.id} value={e.id}>
                                {e.names.lastName}, {e.names.firstName} ({e.alias})
                            </option>
                        ))}
                    </select>
                );
            }
        }
        return <></>;
    };
    
    return (
        <Card className="col-4">
            <Container fluid={true}>
                <Card className="shadow-none">
                    <CardBody>
    
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Caregiver</Label>
                                    <CaregiverDropdown />
                                </div>
                            </Col>
                        </Row>
    
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Patient</Label>
                                    <PatientDropdown />
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-12">
                                <div className="mt-3">
                                    <Label>Relationship Type</Label>
                                    <RelationshipDropdown />
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>&nbsp;</Row>
                        
                        <Button className="form-control-lg btn-info" onClick={validate}>Submit</Button>
                        {redirect}
                    </CardBody>
                </Card>
            </Container>
        </Card>
    );
};
