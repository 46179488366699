import React        from "react";
import BarLoader    from "react-spinners/BarLoader";
import { useQuery } from "urql";

export const PatientSelector = ({query,register}) => {
    const [result] = useQuery({
        query,
        requestPolicy: "network-only"
    });
    
    const {data, fetching, error} = result;
    
    if (fetching) {
        return (
            <>
                <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>
            </>
        );
    } else if (error) {
        return (
            <>
                <p>Oh no... {error.message}</p>
            </>
        );
    } else {
        return (
            <>
                <select name="patients" className={"form-control form-control-lg"} {...register("patientID")} aria-label="Patients">
                    {data.getAllPatients.map((item, index) => (
                        <option key={index} value={JSON.stringify({id: item.id, alias: item.alias})}>{item.alias}</option>
                    ))}
                </select>
            </>
        );
    }
};
