import React, { useEffect, useState }                         from "react";
import { useForm }                                            from "react-hook-form";
import { Redirect }                                           from "react-router-dom";
import BarLoader                                              from "react-spinners/BarLoader";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { useMutation, useQuery }                              from "urql";
import { API_URL }                                            from "../../App";
import "../../assets/scss/custom.scss"


const M_REGISTER_PHYSICIAN = `
mutation registerPhysician($firstName: String!, $lastName: String!, $emailOrPhone: String!, $password: String!, $addressOne: String, $addressTwo: String, $city: String, $region: String, $postalCode: String, $country: String, $timezoneCode: String, $gender: String, $ethnicity: String, $race: String) {
  registerPhysician(firstName: $firstName, lastName: $lastName, emailOrPhone: $emailOrPhone, password: $password, addressOne: $addressOne, addressTwo: $addressTwo, city: $city, region: $region, postalCode: $postalCode, country: $country, timezoneCode: $timezoneCode, gender: $gender, race: $race, ethnicity: $ethnicity)}
`;

const M_UPDATE_PHYSICIAN = `
mutation updatePhysician($id: String!, $firstName: String!, $lastName: String!, $emailOrPhone: String!, $addressOne: String, $addressTwo: String, $city: String, $region: String, $postalCode: String, $country: String, $timezoneCode: String, $gender: String, $ethnicity: String, $race: String) {
  updatePhysician(id: $id, firstName: $firstName, lastName: $lastName, emailOrPhone: $emailOrPhone, addressOne: $addressOne, addressTwo: $addressTwo, city: $city, region: $region, postalCode: $postalCode, country: $country, timezoneCode: $timezoneCode, gender: $gender, race: $race, ethnicity: $ethnicity)}
`;

const Q_GET_TIMEZONES = `
query timezones {
    timezones {
        id
        displayLong,
        abbreviation
    }
}
`;

const Q_GET_PYSICIAN_BY_ID = `
query getPhysicianBy ($id: String) {
    getPhysicianBy (id: $id) {
        id
        role
        names {
            firstName
            lastName
        }
        demographics {
            gender
            race
            ethnicity
        }
        address {
            addressOne
            addressTwo
            city
            region
            postalCode
            country
        }
        timezone {
            id
            displayLong
            displayShort
            abbreviation
            offset
        }
        credentials {
            emailOrPhone
        }
    }
}
`;

export const PMUserPhysicianForm = (props) => {
    
    //console.log("props", props);
    //console.log("content", JSON.parse(props.content));
    const content = props.content ? JSON.parse(props.content) : {context: "create"};
    const [contentID, setContentID] = useState(content.id || "")
    const [editPassword, setEditPassword] = useState(false);
    
    const FORM_CONTEXT = content.context === 'edit' ? 'edit' : 'create';
    
    console.log("FORM_CONTEXT", FORM_CONTEXT);
    
    const minLength = 2;
    const maxLength = 64;
    
    const [formData, setFormData] = useState({
        emailOrPhone: "",
        firstName: "",
        lastName: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        region: "",
        postalCode: "",
        country: "",
        timezoneCode: "ET",
        ethnicity: "001",
        gender: "001",
        race: "001"
    })
    
    const {register, getValues, handleSubmit, formState: {errors}, reset, setValue} = useForm({
        defaultValues: formData
    });
    
    const fieldOptionsName = {required: true, minLength, maxLength}
    const fieldOptionsAddress = {required: false, minLength, maxLength}
    
    const [registerPhysicianResult, registerPhysician] = useMutation(M_REGISTER_PHYSICIAN);
    const [updatePhysicianResult, updatePhysician] = useMutation(M_UPDATE_PHYSICIAN);
    const [redirect, setRedirect] = useState(<></>);
    
    
    const onTimezoneSelected = (e) => {
        setValue("timezoneCode", e.target.Value);
    }
    
    const onRaceSelected = (e) => {
        setValue("race", e.target.Value);
    }
    
    const onEthnicitySelected = (e) => {
        setValue("ethnicity", e.target.Value);
    }
    
    const onGenderSelected = (e) => {
        setValue("gender", e.target.Value);
    }
    
    useEffect(() => {
        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: Q_GET_PYSICIAN_BY_ID,
                variables: {
                    id: contentID,
                },
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.data) {
                    let d = data.data.getPhysicianBy ? data.data.getPhysicianBy : [];
                    if (d.length === 1) {
                        let physician = d[0];
                        console.log(physician);
                        setFormData({
                            emailOrPhone: physician.credentials.emailOrPhone,
                            firstName: physician.names.firstName,
                            lastName: physician.names.lastName,
                            location: physician.address.country,
                            addressOne: physician.address.addressOne || "",
                            addressTwo: physician.address.addressTwo || "",
                            city: physician.address.city || "",
                            region: physician.address.region || "",
                            postalCode: physician.address.postalCode || "",
                            country: physician.address.country || "",
                            timezoneCode: physician.timezone.abbreviation || "",
                            ethnicity: physician.demographics.ethnicity.toString() || "",
                            gender: physician.demographics.gender || "",
                            race: physician.demographics.race || ""
                        });
                    }
                }
            });
    }, [contentID])
    
    useEffect(() => {
        reset(formData);
    }, [reset, formData]);
    
    const TimezoneDropdown = () => {
        const [result] = useQuery({
            query: Q_GET_TIMEZONES,
            requestPolicy: "cache-first",
            pollInterval: 0,
        });
        const {data, fetching, error} = result;
        if (fetching) {
            return <BarLoader width={"100%"} color={"#23CCAA"} height={4}/>;
        } else if (error) {
            return <p>Could not find timezones</p>;
        } else {
            let d = data.timezones ? data.timezones : [];
            if (d.length !== 0) {
                return (
                    <select name="timezones" onChange={onTimezoneSelected} className={"form-control form-control-lg"} {...register("timezoneCode")}>
                        {d.map(tz => (
                            <option key={tz.id} value={tz.abbreviation}>
                                {tz.displayLong}
                            </option>
                        ))}
                    </select>
                );
            }
        }
        return <></>;
    };
    
    const onSubmit = (data) => {
        delete data.location; //TODO remove
        delete data.passwordConfirm;
        if (FORM_CONTEXT === 'edit') {
            if (!editPassword) {
                delete data.password;
            }
            data.id = content.id;
            updatePhysician(data).then(result => {
                if (result.data && result.data.updatePhysician === true) {
                    alert("Provider Successfully Updated");
                    setRedirect(<Redirect to={"users"}/>)
                } else {
                    alert("Could not update Provider. Please ensure email and password have not previously been used")
                }
            });
        } else {
            registerPhysician(data).then(result => {
                if (result.data && result.data.registerPhysician === true) {
                    alert("Provider Successfully Created");
                    setRedirect(<Redirect to={"users"}/>)
                } else {
                    alert("Could not create Provider. Please ensure email and password have not previously been used")
                }
            });
        }
    }
    
    const validate = () => {
        console.log(errors);
        handleSubmit(onSubmit)();
    }
    
    const getClassName = (attribute) => {
        let cn = "form-control form-control-lg w-100 ";
        
        if (errors[attribute]) {
            cn += "form-error"
        }
        
        return cn;
    }
    
    const getErrorJSX = (attribute, subtype, label) => {
        if (errors[attribute] && errors[attribute].type === subtype) {
            return <span role="alert" className={"right"}>{label}</span>
        }
    }
    
    const topLevelClassNameForDrawer = FORM_CONTEXT === 'edit' ? 'drawer' : "col-12";
    const closeButtonComponent = FORM_CONTEXT === 'edit' ? (
        <button className="btn btn-sm waves-effect waves-light" onClick={() => {
            props.closeFxn();
        }}>
            <i className="mdi mdi-close mdi-24px"/>
        </button>
    ) : (<></>);
    
    
    const [editButtonComponent, setEditButtonComponent] = useState(FORM_CONTEXT === 'edit' ? (
        <Button className="btn btn-sm form-control-lg btn-default" onClick={() => {
            setEditPassword(!editPassword);
        }}>
            <i className="mdi mdi-account-edit-outline mdi-18px"/>Reset Password Off
        </Button>
    ) : (<></>));
    
    
    useEffect(() => {
        console.log("editPassword", editPassword);
        if (FORM_CONTEXT === 'edit') {
            if (editPassword) {
                setEditButtonComponent(
                    <Button className="btn btn-sm form-control-lg btn-success" onClick={() => {
                        setEditPassword(!editPassword);
                    }}>
                        <i className="mdi mdi-account-edit mdi-18px"/>Reset Password On
                    </Button>
                )
            } else {
                setEditButtonComponent(
                    <Button className="btn btn-sm form-control-lg btn-default" onClick={() => {
                        setEditPassword(!editPassword);
                    }}>
                        <i className="mdi mdi-account-edit-outline mdi-18px"/>Reset Password Off
                    </Button>
                )
            }
        }
    }, [editPassword])
    
    
    return (
        <Card className={topLevelClassNameForDrawer}>
            <Container fluid={true}>
                <Card className="shadow-none">
                    <CardBody>
                        <Row>
                            <Col className="col-1">
                                {closeButtonComponent}
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <h4>Core Profile</h4>
                                </div>
                            </Col>
                            <Col className="col-5">
                                <div className="mt-3">
                                    <h4>Geographics</h4>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <h4>Demographics</h4>
                                </div>
                            </Col>
                        </Row>
                        
                        
                        <Row>
                            <Col className="col-4">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Email</Label><br/>
                                                <input className={getClassName("emailOrPhone")} type="email" placeholder="firstname.lastname@email.com" {...register("emailOrPhone", {required: true, minLength: 10, pattern: /^\S+@\S+$/i})}/>
                                                {getErrorJSX("emailOrPhone", "required", "This field is required")}
                                                {getErrorJSX("emailOrPhone", "minLength", "Must be at least 10 characters")}
                                                {getErrorJSX("emailOrPhone", "pattern", "This does not appear to be a valid email pattern")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Password</Label><br/>
                                                <input className={getClassName("password")} type="password" placeholder="veryStrongPassword123!" {...register("password", {
                                                    required: FORM_CONTEXT !== 'edit' || editPassword,
                                                    minLength: 10,
                                                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i
                                                })}/>
                                                <span role="contentinfo"
                                                      className={"right"}> Must must have<br/>  - 1 UPPERCASE letter<br/>  - 1 lowercase letter<br/>  - 1 digit<br/>  - 1 special character<br/>  - be minimum <b>10</b> characters</span>
                                                {getErrorJSX("password", "required", "This field is required")}
                                                {getErrorJSX("password", "minLength", "Must be at least 10 characters")}
                                                {getErrorJSX("password", "pattern", "Password must have at least one upper case letter, one lower case letter, one digit, one special character, and be a minimum ten characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Confirm Password</Label><br/>
                                                <input className={getClassName("passwordConfirm")} type="password" placeholder="veryStrongPassword123!" {...register("passwordConfirm", {validate: value => value === getValues("password")})}/>
                                                {getErrorJSX("passwordConfirm", "validate", "The passwords do not match")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>First Name</Label><br/>
                                                <input className={getClassName("firstName")} type="text" placeholder="Your First/Given Name" {...register("firstName", fieldOptionsName)} />
                                                {getErrorJSX("firstName", "required", "This field is required")}
                                                {getErrorJSX("firstName", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("firstName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Last Name</Label><br/>
                                                <input className={getClassName("lastName")} type="text" placeholder="Your Legal Last Name" {...register("lastName", fieldOptionsName)} />
                                                {getErrorJSX("lastName", "required", "This field is required")}
                                                {getErrorJSX("lastName", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("lastName", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Timezone</Label><br/>
                                                <TimezoneDropdown/>
                                            </div>
                                        </Col>
                                    </Row>
                                
                                </div>
                            </Col>
                            <Col className="col-5">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Location Name (Not Used ATM!)</Label><br/>
                                                <input className={getClassName("location")} type="text" placeholder="Bellevue" {...register("location", {...fieldOptionsName, required: false, minLength: 2})} />
                                                {getErrorJSX("location", "minLength", "Must be at least 2 characters")}
                                                {getErrorJSX("location", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Address 1 (Optional)</Label><br/>
                                                <input className={getClassName("addressOne")} type="text" placeholder="123 Acme Boulevard" {...register("addressOne", fieldOptionsAddress)} />
                                                {getErrorJSX("addressOne", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("addressOne", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Address 2 (Optional)</Label><br/>
                                                <input className={getClassName("addressTwo")} type="text" placeholder="Suite 45" {...register("addressTwo", fieldOptionsAddress)} />
                                                {getErrorJSX("addressTwo", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("addressTwo", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>City (Optional)</Label><br/>
                                                <input className={getClassName("city")} type="text" placeholder="Paperville" {...register("city", fieldOptionsAddress)} />
                                                {getErrorJSX("city", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("city", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>State/Province/Region (Optional)</Label><br/>
                                                <input className={getClassName("region")} type="text" placeholder="Ohio" {...register("region", {...fieldOptionsAddress, minLength: 2})} />
                                                {getErrorJSX("region", "minLength", "Must be at least 2 characters")}
                                                {getErrorJSX("region", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Zip/Postal Code (Optional)</Label><br/>
                                                <input className={getClassName("postalCode")} type="text" placeholder="11420" {...register("postalCode", fieldOptionsAddress)} />
                                                {getErrorJSX("postalCode", "minLength", "Must be at least 4 characters")}
                                                {getErrorJSX("postalCode", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Country (Optional)</Label><br/>
                                                <input className={getClassName("country")} type="text" placeholder="USA"  {...register("country", {...fieldOptionsAddress, minLength: 2})} />
                                                {getErrorJSX("country", "minLength", "Must be at least 2 characters")}
                                                {getErrorJSX("country", "maxLength", "Must not exceed 64 characters")}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className="mt-3">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Sex (Optional)</Label><br/>
                                                <select name="genders" onChange={onGenderSelected} className={"form-control form-control-lg"} {...register("gender", {required: false})}>
                                                    <option key={"001"} value={"001"}>Male</option>
                                                    <option key={"002"} value={"002"}>Female</option>
                                                    <option key={"003"} value={"003"}>Intersex</option>
                                                    <option key={"004"} value={"004"}>Trans-male</option>
                                                    <option key={"005"} value={"005"}>Trans-female</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Race (Optional)</Label><br/>
                                                <select name="races" onChange={onRaceSelected} className={"form-control form-control-lg"} {...register("race", {required: false})}>
                                                    <option key={"001"} value={"001"}>White</option>
                                                    <option key={"002"} value={"002"}>Black or African American</option>
                                                    <option key={"003"} value={"003"}>American Indian and Alaska Native</option>
                                                    <option key={"004"} value={"004"}>Asian</option>
                                                    <option key={"005"} value={"005"}>Native Hawaiian and Other Pacific Islander</option>
                                                    <option key={"006"} value={"006"}>Two or More Races</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mt-3">
                                                <Label>Ethnicity (Optional)</Label><br/>
                                                <select name="ethnicities" className={"form-control form-control-lg"} onChange={onEthnicitySelected} {...register("ethnicity", {required: false})}>
                                                    <option key={"001"} value={"001"}>Hispanic or Latino</option>
                                                    <option key={"002"} value={"002"}>Non-Hispanic or Latino</option>
                                                    <option key={"090"} value={"090"}>Other</option>
                                                    <option key={"099"} value={"099"}>Prefer Not To Say/Unknown</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col className="col-9">
                                &nbsp;
                            </Col>
                            <Col className="col-2">
                                {editButtonComponent}
                            </Col>
                            <Col className="col-1">
                                <Button className="form-control-lg btn-info" onClick={validate}>Submit</Button>
                                {redirect}
                            </Col>
                        </Row>
                    
                    </CardBody>
                </Card>
            </Container>
        </Card>
    );
};
