export const FORM_CONTEXT_SPEC_CREATE = 'c';
export const FORM_CONTEXT_SPEC_EDIT = 'e';

export const FORM_CONTEXT_SKILL_CREATE = 'skc';
export const FORM_CONTEXT_SKILL_EDIT = 'ske';

export const SPEC_TYPE_ANT = "antecedent";
export const SPEC_TYPE_BHV = "behaviour";
export const SPEC_TYPE_CSQ = "consequence";
export const SPEC_TYPE_SKL = "skill";

export const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};
